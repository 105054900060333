import React, { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, VStack, Input, Select, Button } from '@chakra-ui/react';
import SharedTestCard from '../components/community/SharedTestCard';
import SharedTestList from '../components/community/SharedTestList';
import FilterBar from '../components/community/FilterBar';
import { getSharedTests } from '../firebase/testOperations';
import { Test } from '../types/test';

const Community: React.FC = () => {
  const [sharedTests, setSharedTests] = useState<Test[]>([]);
  const [filteredTests, setFilteredTests] = useState<Test[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('recent');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  useEffect(() => {
    const fetchSharedTests = async () => {
      const tests = await getSharedTests();
      console.log('Fetched shared tests:', tests);
      setSharedTests(tests);
      setFilteredTests(tests);
    };
    fetchSharedTests();
  }, []);

  useEffect(() => {
    const filtered = sharedTests.filter(test => 
      test.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sorted = [...filtered].sort((a, b) => {
      if (sortBy === 'recent') {
        const getTime = (date: any) => {
          if (date?.toMillis) return date.toMillis();
          if (date instanceof Date) return date.getTime();
          return 0;
        };
        return getTime(b.sharedAt) - getTime(a.sharedAt);
      } else if (sortBy === 'popular') {
        return (b.stats?.attempts || 0) - (a.stats?.attempts || 0);
      }
      return 0;
    });
    setFilteredTests(sorted);
    console.log('Filtered tests:', filteredTests);
  }, [sharedTests, searchTerm, sortBy]);

  return (
    <Box maxWidth="1200px" margin="0 auto" padding={8}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="2xl" textAlign="center">
          Community Tests
        </Heading>
        <FilterBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <Box>
          <Button onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}>
            Switch to {viewMode === 'grid' ? 'List' : 'Grid'} View
          </Button>
        </Box>
        {viewMode === 'grid' ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {filteredTests.map(test => (
              <SharedTestCard key={test.id} test={test} questionCount={test.questionCount || 0} />
            ))}
          </SimpleGrid>
        ) : (
          <SharedTestList tests={filteredTests.map(test => ({ ...test, questionCount: test.questionCount || 0 }))} />
        )}
      </VStack>
    </Box>
  );
};

export default Community;
