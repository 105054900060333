// src/context/ProgressContext.tsx
import React, { createContext, useState, useEffect } from "react";
import { Module } from "../types/module";

interface ProgressContextProps {
  progress: number;
  calculateProgress: (modules: Module[]) => void;
  setModules: React.Dispatch<React.SetStateAction<Module[]>>;
  loadingModules: boolean;
}

const ProgressContext = createContext<ProgressContextProps | undefined>(
  undefined
);

export const ProgressProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [progress, setProgress] = useState(0);
  const [modules, setModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(true);

  const calculateProgress = (modules: Module[]) => {
    let totalTopics = 0;
    let completedTopics = 0;

    modules.forEach((module) => {
      module.blocks.forEach((block) => {
        block.topics.forEach((topic) => {
          totalTopics++;
          if (topic.status === "Completado") {
            completedTopics++;
          }
        });
      });
    });

    const newProgress =
      totalTopics === 0 ? 0 : (completedTopics / totalTopics) * 100;
    setProgress(newProgress);
    setLoadingModules(false); // Indicar que los módulos se han cargado
  };

  useEffect(() => {
    calculateProgress(modules);
  }, [modules]);

  return (
    <ProgressContext.Provider
      value={{ progress, calculateProgress, setModules, loadingModules }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressContext;
