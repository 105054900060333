import React from 'react';
import { Text, Button, Card, CardHeader, VStack, Box, Badge, Flex } from '@chakra-ui/react';
import { Test } from '../../types/test';
import { useAuth } from '../../hooks/useAuth';
import { importSharedTest } from '../../firebase/testOperations';

interface SharedTestCardProps {
  test: Test;
  questionCount: number;
}

const SharedTestCard: React.FC<SharedTestCardProps> = ({ test, questionCount }) => {
  const { currentUser } = useAuth();

  const handleImport = async () => {
    try {
      if (currentUser?.uid) {
        await importSharedTest(test.id, currentUser.uid);
        // Add feedback here (e.g., toast notification)
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error importing test:', error);
      // Handle the error
    }
  };

  return (
    <Card
      variant="outline"
      borderRadius="md"
      overflow="hidden"
      borderWidth="1px"
      mb={2}
      boxShadow="sm"
      p={2}
      position="relative"
    >
      <Badge 
        colorScheme="purple" 
        position="absolute" 
        top={2} 
        right={2}
        textTransform="none"
      >
        By: {test.authorName}
      </Badge>

      <CardHeader p={2} bg="white" borderBottomWidth="0px">
        <VStack spacing={2} align="start" width="100%">
          <Text fontSize="lg" fontWeight="bold" noOfLines={1}>
            {test.title}
          </Text>
          <Text fontSize="xs" color="gray.600">
            Preguntas totales: {questionCount}
          </Text>
          <Box minH="30px" w="full">
            <Text fontSize="sm" color="gray.600" noOfLines={2}>
              {test.description}
            </Text>
          </Box>
          <Flex justifyContent="center" width="100%" mt="auto" pt={2}>
            <Button 
              size="sm" 
              colorScheme="blue" 
              onClick={handleImport}
            >
              Importar Test
            </Button>
          </Flex>
        </VStack>
      </CardHeader>
    </Card>
  );
};

export default SharedTestCard;