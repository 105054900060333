import React, { useState, useEffect } from "react";
import { Box, Heading, Flex, Fade } from "@chakra-ui/react";
import CitationForm from "../components/citation/CitationForm";
import { CitationData } from "../types/citation";

const CitationGenerator: React.FC = () => {
  const [history, setHistory] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Add a small delay to make the fade-in effect more noticeable
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const handleGenerateCitation = (citationData: CitationData) => {
    const newCitation = `${citationData.author} (${citationData.year}). ${citationData.title}. ${citationData.city}: ${citationData.publisher}.`;
    setHistory([newCitation, ...history]);
  };

  return (
    <Box p={8} mt={2} maxW="800px" mx="auto">
      <Flex justify="left" mb={6} mt={4}>
        <Heading as="h1" size="lg">
          Generador de Citas
        </Heading>
      </Flex>
      <Fade in={isLoaded} transition={{ enter: { duration: 0.5 } }}>
        <CitationForm onGenerateCitation={handleGenerateCitation} />
        {/* You might want to display the citation history here */}
        {/* 
        <VStack mt={6} align="stretch">
          {history.map((citation, index) => (
            <Text key={index}>{citation}</Text>
          ))}
        </VStack>
        */}
      </Fade>
    </Box>
  );
};

export default CitationGenerator;
