import React from "react";
import { Box, Progress, Text } from "@chakra-ui/react";

interface ProgressBarProps {
  progress: number; // Progreso en porcentaje
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <Box mb={4}>
      <Progress value={progress} colorScheme="teal" size="md" width="200px" />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Text fontSize="sm" color="gray.500">
          Completado: {progress.toFixed(2)}%{" "}
        </Text>
      </Box>
    </Box>
  );
};

export default ProgressBar;
