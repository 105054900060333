// src/pages/Login.tsx
import React from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import LoginForm from "../components/auth/LoginForm";

const Login: React.FC = () => {
  return (
    <Flex
      height="70vh"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      pt={{ base: "0", md: "2" }} // Reduce el padding superior para mover el formulario más cerca del header
    >
      <Box
        maxW="sm"
        width="100%"
        p={8}
        bg="white"
        borderRadius="md"
        boxShadow="lg"
        mt={{ base: "0", md: "2" }} // Reduce el margen superior para acercar aún más el formulario al header
        mb={{ base: "8", md: "10" }} // Ajusta el margen inferior para mantener suficiente espacio debajo del formulario
      >
        <Heading mb={4} textAlign="center">
          Iniciar sesión
        </Heading>
        <LoginForm />
      </Box>
    </Flex>
  );
};

export default Login;
