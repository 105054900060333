import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { Link, LinksModalProps } from "../../types/link";

const LinksModal: React.FC<LinksModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialLink,
}) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const toast = useToast();

  useEffect(() => {
    if (initialLink) {
      setTitle(initialLink.title);
      setUrl(initialLink.url);
      setDescription(initialLink.description);
    } else {
      setTitle("");
      setUrl("");
      setDescription("");
    }
  }, [initialLink, isOpen]);

  const handleSaveClick = () => {
    if (!title || !url) {
      toast({
        title: "Campos vacíos",
        description: "Por favor, completa todos los campos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const link: Link = {
      id: initialLink ? initialLink.id : Date.now().toString(),
      title,
      url,
      description,
    };

    onSave(link);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {initialLink ? "Editar Enlace" : "Agregar Enlace"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Título</FormLabel>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={50}
              placeholder="Ingrese el título del enlace"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Enlace</FormLabel>
            <Input 
              value={url} 
              onChange={(e) => setUrl(e.target.value)} 
              placeholder="https://ejemplo.com"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Descripción</FormLabel>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Agrega una breve descripción del enlace"
              sx={{
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            />
          </FormControl>
          <Flex justifyContent="center" mt={4} gap={4}>
            <Button colorScheme="gray" onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="teal" onClick={handleSaveClick}>
              Guardar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LinksModal;
