import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Box,
  Text,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { FiUpload, FiFile } from "react-icons/fi";

interface PdfUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  handlePdfUpload: (file: File) => void;
}

const PdfUploadModal: React.FC<PdfUploadModalProps> = ({
  isOpen,
  onClose,
  handlePdfUpload,
}) => {
  const toast = useToast();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0] || null;
    setFile(uploadedFile);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleUpload = () => {
    if (file) {
      handlePdfUpload(file);
      toast({
        title: "PDF subido correctamente",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subir PDF</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            direction="column"
            align="center"
            justify="center"
            p={6}
            borderWidth={2}
            borderRadius="md"
            borderColor="gray.300"
            borderStyle="dashed"
            bg="gray.50"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
            cursor="pointer"
          >
            <Icon as={FiUpload} w={10} h={10} color="gray.400" mb={4} />
            <Text fontWeight="bold" mb={2}>
              {file ? file.name : "Arrastra y suelta tu archivo PDF aquí"}
            </Text>
            <Text fontSize="sm" color="gray.500">
              o haz clic para seleccionar
            </Text>
            <input
              type="file"
              accept=".pdf"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </Flex>
          {file && (
            <Box mt={4}>
              <Flex align="center">
                <Icon as={FiFile} mr={2} />
                <Text>{file.name}</Text>
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={handleUpload} disabled={!file}>
            Subir
          </Button>
          <Button variant="ghost" ml={3} onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PdfUploadModal;