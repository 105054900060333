import React from "react";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { AiFillHeart } from "react-icons/ai";

const Footer: React.FC = () => {
  return (
    <Box
      bg="teal"
      h={{ base: "60px", md: "40px" }} // Ajusta la altura para dispositivos móviles y de escritorio
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      px={4}
      flexDirection={{ base: "column", md: "row" }} // Cambia la dirección del contenido en dispositivos móviles
    >
      <Flex
        align="center"
        justify={{ base: "center", md: "center" }} // Centra el contenido en dispositivos móviles
        mb={{ base: 2, md: 0 }} // Añade margen en la parte inferior en dispositivos móviles
        mt={{ base: 2, md: 0 }} // Añade margen en la parte inferior en dispositivos móviles
      >
        Creado por{" "}
        <Link
          href="https://github.com/serjlc"
          isExternal
          color="white"
          fontWeight="bold"
          mx={1}
        >
          serjlc
        </Link>{" "}
        con <Box as={AiFillHeart} color="white" ml={1} mt={0.5} boxSize={3.5} />
      </Flex>
      <Text
        position={{ base: "relative", md: "absolute" }} // Cambia la posición en dispositivos móviles
        right={{ base: "0", md: "10px" }} // Ajusta la posición derecha en dispositivos móviles
        fontSize="sm"
      >
        1.0.0-beta.1
      </Text>
    </Box>
  );
};

export default Footer;
