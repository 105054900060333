// src/content/tooltips.ts

const tooltips = {
  progress: "Visualiza el progreso general de tus módulos.",
  latestSummaries: "Consulta los últimos sumarios del BOE.",
  calendar: "Selecciona una fecha para crear un evento",
  upcomingEvents: "Revisa los eventos próximos en tu calendario.",
};

export default tooltips;
