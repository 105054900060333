import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  Link as ChakraLink,
  Tooltip,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye, AiOutlineShareAlt } from "react-icons/ai";
import { Link } from "../../types/link";

interface LinkCardProps {
  link: Link;
  onVisit: (url: string) => void;
  onShare: (url: string) => void;
  onEdit: (link: Link) => void;
  onDelete: (id: string) => void;
}

const LinkCard: React.FC<LinkCardProps> = ({ link, onVisit, onShare, onEdit, onDelete }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      p={3}
      borderWidth="1px"
      borderRadius="md"
      borderColor={borderColor}
      boxShadow="sm"
      bg={bgColor}
    >
      <Flex justify="space-between" align="center">
        <Flex align="center" flex="1" mr={2}>
          <Image
            src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=32`}
            alt="Website favicon"
            boxSize="16px"
            mr={2}
          />
          <Text fontSize="md" fontWeight="semibold" isTruncated>
            {link.title}
          </Text>
        </Flex>
        <Flex>
          <Tooltip label="Visit">
            <IconButton
              icon={<AiOutlineEye />}
              aria-label="Visit"
              onClick={() => onVisit(link.url)}
              size="md"
              variant="ghost"
              colorScheme="blue"
              mr={1}
            />
          </Tooltip>
          <Tooltip label="Share">
            <IconButton
              icon={<AiOutlineShareAlt />}
              aria-label="Share"
              onClick={() => onShare(link.url)}
              size="md"
              variant="ghost"
              colorScheme="green"
              mr={1}
            />
          </Tooltip>
          <Tooltip label="Edit">
            <IconButton
              icon={<AiOutlineEdit />}
              aria-label="Edit"
              onClick={() => onEdit(link)}
              size="md"
              variant="ghost"
              colorScheme="yellow"
              mr={1}
            />
          </Tooltip>
          <Tooltip label="Delete">
            <IconButton
              icon={<AiOutlineDelete />}
              aria-label="Delete"
              onClick={() => onDelete(link.id)}
              size="md"
              variant="ghost"
              colorScheme="red"
            />
          </Tooltip>
        </Flex>
      </Flex>
      <ChakraLink
        href={link.url}
        isExternal
        color="teal.500"
        fontSize="xs"
        mt={1}
        display="block"
        isTruncated
      >
        {link.url}
      </ChakraLink>
      <Text fontSize="xs" color="gray.600" mt={1} noOfLines={1}>
        {link.description}
      </Text>
    </Box>
  );
};

export default LinkCard;