import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Heading,
  VStack,
  Button,
  useDisclosure,
  IconButton,
  Flex,
  useToast,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  Tag,
  Tooltip,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
  Fade,
} from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  AttachmentIcon,
  ViewIcon,
  CloseIcon,
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { useAuth } from "../hooks/useAuth";
import {
  getModules,
  createModule,
  updateModule,
  updateBlock,
  updateTopic,
  deleteModule,
  deleteBlock,
  deleteTopic,
  uploadTopicPdf,
  deleteTopicPdf,
} from "../firebase/moduleOperations";
import { Module, Block, Topic } from "../types/module";
import AddEditModal from "../components/modules/AddEditModal";
import { useProgress } from "../hooks/useProgress";
import PdfUploadModal from "../components/modules/PdfUploadModal";
import { TOAST_MESSAGES } from "../content/toastMessages";

const Modules: React.FC = () => {
  const { currentUser } = useAuth();
  const toast = useToast();
  const { calculateProgress } = useProgress();
  const [modules, setModules] = useState<Module[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    isOpen: isModuleModalOpen,
    onOpen: onOpenModuleModal,
    onClose: onCloseModuleModal,
  } = useDisclosure();
  const [name, setName] = useState("");
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [entity, setEntity] = useState<"Módulo" | "Bloque" | "Tema">("Módulo");
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [selectedTopicForPdf, setSelectedTopicForPdf] = useState<Topic | null>(null);
  const [moduleToDelete, setModuleToDelete] = useState<string | null>(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const [allExpanded, setAllExpanded] = useState(true);
  const [selectedModuleId, setSelectedModuleId] = useState<string | null>(null);

  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const iconButtonSize = useBreakpointValue({ base: "sm", md: "sm" });
  const headingSize = useBreakpointValue({ base: "md", md: "lg" });

  useEffect(() => {
    const fetchModules = async () => {
      if (currentUser) {
        setIsLoading(true);
        try {
          const userModules = await getModules(currentUser.uid);
          const sortedModules = userModules.sort((a, b) => a.order - b.order);
          setModules(sortedModules);
          setExpandedIndexes(sortedModules.map((_, index) => index));
          calculateProgress(sortedModules);
        } catch (error) {
          console.error("Error fetching modules:", error);
          toast({
            title: TOAST_MESSAGES.FETCH_ERROR.title,
            description: TOAST_MESSAGES.FETCH_ERROR.description,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchModules();
  }, [currentUser, calculateProgress, toast]);

  const handleAddOrUpdate = async () => {
    if (!name.trim()) {
      toast({
        title: TOAST_MESSAGES.INCOMPLETE_FIELDS.title,
        description: TOAST_MESSAGES.INCOMPLETE_FIELDS.description,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (currentUser) {
      try {
        let success = false;
        switch (entity) {
          case "Módulo":
            if (isEditing && selectedModule) {
              await handleAddOrUpdateModule();
              success = true;
            } else {
              await handleAddModule();
              success = true;
            }
            break;
          case "Bloque":
            if (isEditing && selectedBlock) {
              await handleAddOrUpdateBlock();
              success = true;
            } else {
              await handleAddBlock();
              // Don't set success to true here, as handleAddBlock may fail
            }
            break;
          case "Tema":
            if (isEditing && selectedTopic) {
              await handleAddOrUpdateTopic();
              success = true;
            } else {
              await handleAddTopic();
              success = true;
            }
            break;
        }
        if (success) {
          setName("");
          setIsEditing(false);
          onCloseModuleModal();
          calculateProgress(modules);
        }
      } catch (error) {
        console.error(`Error adding/updating ${entity}:`, error);
        toast({
          title: "Error",
          description: `Hubo un problema al agregar/actualizar el ${entity.toLowerCase()}.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleAddModule = async () => {
    if (!currentUser) return;
    const newModule: Module = {
      id: `${Date.now()}-${Math.random()}`,
      name,
      blocks: [],
      order: modules.length, // Add this line
    };
    const moduleId = await createModule(currentUser.uid, newModule);
    setModules((prevModules) => [...prevModules, { ...newModule, id: moduleId }]);
    setExpandedIndexes((prev) => [...prev, modules.length]); 
    toast({
      title: "Módulo agregado",
      description: `El módulo "${name}" ha sido agregado.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleAddBlock = async () => {
    if (!currentUser || !selectedModuleId) {
      toast({
        title: TOAST_MESSAGES.BLOCK.NO_MODULE_SELECTED.title,
        description: TOAST_MESSAGES.BLOCK.NO_MODULE_SELECTED.description,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Return early without closing the modal
    }

    const selectedModule = modules.find(m => m.id === selectedModuleId);
    if (!selectedModule) return;

    const newBlock: Block = {
      id: `${selectedModuleId}-${Date.now()}-${Math.random()}`,
      name,
      topics: [],
    };
    const updatedModules = modules.map((m) =>
      m.id === selectedModuleId ? { ...m, blocks: [...m.blocks, newBlock] } : m
    );
    setModules(updatedModules);
    await updateModule(currentUser.uid, selectedModuleId, updatedModules.find(m => m.id === selectedModuleId)!);

    const moduleIndex = modules.findIndex(m => m.id === selectedModuleId);
    if (moduleIndex !== -1 && !expandedIndexes.includes(moduleIndex)) {
      setExpandedIndexes((prev) => [...prev, moduleIndex]);
    }

    toast({
      title: TOAST_MESSAGES.BLOCK.CREATE.title,
      description: TOAST_MESSAGES.BLOCK.CREATE.description(name),
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    // Only close the modal if the block was successfully added
    onCloseModuleModal();
  };

  const handleAddTopic = async () => {
    if (selectedModule && selectedBlock) {
      const newTopic: Topic = {
        id: `${selectedBlock.id}-${Date.now()}-${Math.random()}`,
        name,
        status: "Sin Empezar",
      };
      const updatedModules = modules.map((module) =>
        module.id === selectedModule.id
          ? {
              ...module,
              blocks: module.blocks.map((block) =>
                block.id === selectedBlock.id
                  ? { ...block, topics: [...block.topics, newTopic] }
                  : block
              ),
            }
          : module
      );
      setModules(updatedModules);
      if (currentUser) {
        await updateModule(currentUser.uid, selectedModule.id, updatedModules.find(m => m.id === selectedModule.id)!);
      }
      toast({
        title: TOAST_MESSAGES.TOPIC.CREATE.title,
        description: TOAST_MESSAGES.TOPIC.CREATE.description(name),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddOrUpdateModule = async () => {
    if (name && currentUser && selectedModule) {
      const updatedModule = { ...selectedModule, name };
      await updateModule(currentUser.uid, selectedModule.id, updatedModule);
      setModules((prevModules) =>
        prevModules.map((module) =>
          module.id === selectedModule.id ? updatedModule : module
        )
      );
      toast({
        title: TOAST_MESSAGES.MODULE.UPDATE.title,
        description: TOAST_MESSAGES.MODULE.UPDATE.description(name),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const handleDeleteModule = async (moduleId: string) => {
    if (currentUser) {
      try {
        await deleteModule(currentUser.uid, moduleId);
        setModules((prevModules) => prevModules.filter((module) => module.id !== moduleId));
        calculateProgress(modules.filter((module) => module.id !== moduleId));
        toast({
          title: TOAST_MESSAGES.MODULE.DELETE.title,
          description: TOAST_MESSAGES.MODULE.DELETE.description,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error deleting module:", error);
        toast({
          title: TOAST_MESSAGES.MODULE.DELETE_ERROR.title,
          description: TOAST_MESSAGES.MODULE.DELETE_ERROR.description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setModuleToDelete(null);
        setIsDeleteAlertOpen(false);
      }
    }
  };

  const handleAddOrUpdateBlock = async () => {
    if (name && currentUser && selectedModule && selectedBlock) {
      const updatedBlock = { ...selectedBlock, name };
      const updatedModules = modules.map((module) =>
        module.id === selectedModule.id
          ? {
              ...module,
              blocks: module.blocks.map((block) =>
                block.id === selectedBlock.id ? updatedBlock : block
              ),
            }
          : module
      );
      setModules(updatedModules);
      await updateBlock(
        currentUser.uid,
        selectedModule.id,
        selectedBlock.id,
        updatedBlock
      );
      toast({
        title: TOAST_MESSAGES.BLOCK.UPDATE.title,
        description: TOAST_MESSAGES.BLOCK.UPDATE.description(name),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteBlock = async (moduleId: string, blockId: string) => {
    if (currentUser) {
      try {
        await deleteBlock(currentUser.uid, moduleId, blockId);
        const updatedModules = modules.map((module) =>
          module.id === moduleId
            ? { ...module, blocks: module.blocks.filter((block) => block.id !== blockId) }
            : module
        );
        setModules(updatedModules);
        calculateProgress(updatedModules);
        toast({
          title: TOAST_MESSAGES.BLOCK.DELETE.title,
          description: TOAST_MESSAGES.BLOCK.DELETE.description,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error deleting block:", error);
        toast({
          title: TOAST_MESSAGES.BLOCK.DELETE_ERROR.title,
          description: TOAST_MESSAGES.BLOCK.DELETE_ERROR.description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleAddOrUpdateTopic = async () => {
    if (name && currentUser && selectedModule && selectedBlock && selectedTopic) {
      try {
        const updatedTopic = { ...selectedTopic, name };
        await updateTopic(
          currentUser.uid,
          selectedModule.id,
          selectedBlock.id,
          selectedTopic.id,
          updatedTopic
        );
        setModules(prevModules => prevModules.map(module => 
          module.id === selectedModule.id
            ? {
                ...module,
                blocks: module.blocks.map(block =>
                  block.id === selectedBlock.id
                    ? {
                        ...block,
                        topics: block.topics.map(topic =>
                          topic.id === selectedTopic.id ? updatedTopic : topic
                        )
                      }
                    : block
                )
              }
            : module
        ));
        toast({
          title: "Tema actualizado",
          description: `El tema "${name}" ha sido actualizado.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error adding/updating topic:", error);
        toast({
          title: "Error",
          description: "Hubo un problema al agregar/actualizar el tema.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleUploadPdf = async (topic: Topic, file: File) => {
    if (currentUser) {
      try {
        const pdfUrl = await uploadTopicPdf(currentUser.uid, topic.id, file);
        const updatedModules = modules.map((module) => ({
          ...module,
          blocks: module.blocks.map((block) => ({
            ...block,
            topics: block.topics.map((t) =>
              t.id === topic.id ? { ...t, pdfUrl } : t
            ),
          })),
        }));
        setModules(updatedModules);
        const moduleToUpdate = updatedModules.find((module) =>
          module.blocks.some((block) =>
            block.topics.some((t) => t.id === topic.id)
          )
        );
        if (moduleToUpdate) {
          await updateModule(
            currentUser.uid,
            moduleToUpdate.id,
            moduleToUpdate
          );
        }
        toast({
          title: TOAST_MESSAGES.PDF.UPLOAD.title,
          description: TOAST_MESSAGES.PDF.UPLOAD.description,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: TOAST_MESSAGES.PDF.UPLOAD_ERROR.title,
          description: TOAST_MESSAGES.PDF.UPLOAD_ERROR.description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleDeletePdf = async (topic: Topic) => {
    if (currentUser && topic.pdfUrl) {
      try {
        await deleteTopicPdf(topic.pdfUrl);
        const updatedModules = modules.map((module) => ({
          ...module,
          blocks: module.blocks.map((block) => ({
            ...block,
            topics: block.topics.map((t) =>
              t.id === topic.id ? { ...t, pdfUrl: "" } : t
            ),
          })),
        }));
        setModules(updatedModules);
        const moduleToUpdate = updatedModules.find((module) =>
          module.blocks.some((block) =>
            block.topics.some((t) => t.id === topic.id)
          )
        );
        if (moduleToUpdate) {
          await updateModule(
            currentUser.uid,
            moduleToUpdate.id,
            moduleToUpdate
          );
        }
        toast({
          title: TOAST_MESSAGES.PDF.DELETE.title,
          description: TOAST_MESSAGES.PDF.DELETE.description,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: TOAST_MESSAGES.PDF.DELETE_ERROR.title,
          description: TOAST_MESSAGES.PDF.DELETE_ERROR.description,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleDeleteTopic = async (
    moduleId: string,
    blockId: string,
    topicId: string
  ) => {
    if (currentUser) {
      await deleteTopic(currentUser.uid, moduleId, blockId, topicId);
      const updatedModules = modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              blocks: module.blocks.map((block) =>
                block.id === blockId
                  ? {
                      ...block,
                      topics: block.topics.filter(
                        (topic) => topic.id !== topicId
                      ),
                    }
                  : block
              ),
            }
          : module
      );
      setModules(updatedModules);
      calculateProgress(updatedModules);
      toast({
        title: TOAST_MESSAGES.TOPIC.DELETE.title,
        description: TOAST_MESSAGES.TOPIC.DELETE.description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleStatusChange = async (
    moduleId: string,
    blockId: string,
    topicId: string,
    status: Topic["status"]
  ) => {
    if (currentUser) {
      const updatedModules = modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              blocks: module.blocks.map((block) =>
                block.id === blockId
                  ? {
                      ...block,
                      topics: block.topics.map((topic) =>
                        topic.id === topicId ? { ...topic, status } : topic
                      ),
                    }
                  : block
              ),
            }
          : module
      );
      setModules(updatedModules);
      const moduleToUpdate = updatedModules.find(
        (module) => module.id === moduleId
      );
      if (moduleToUpdate) {
        await updateModule(currentUser.uid, moduleId, moduleToUpdate);
      }
      calculateProgress(updatedModules);
    }
  };

  const handleOpenPdfModal = (topic: Topic) => {
    setSelectedTopicForPdf(topic);
    setIsPdfModalOpen(true);
  };

  const handleClosePdfModal = () => {
    setIsPdfModalOpen(false);
    setSelectedTopicForPdf(null);
  };
  const handlePdfUpload = (file: File) => {
    if (selectedTopicForPdf) {
      handleUploadPdf(selectedTopicForPdf, file);
    }
  };

  const toggleAllAccordions = () => {
    if (allExpanded) {
      setExpandedIndexes([]);
    } else {
      setExpandedIndexes(modules.map((_, index) => index));
    }
    setAllExpanded(!allExpanded);
  };



  return (
    <Box p={8} mt={2} maxW="1200px" mx="auto">
      <Flex 
        direction={{ base: "column", md: "row" }} 
        justify="space-between" 
        align={{ base: "flex-start", md: "center" }} 
        mb={8}
        mt={4}
      >
        <Heading as="h1" size={headingSize} mb={{ base: 4, md: 0 }}>
          Módulos
        </Heading>
        <Flex direction={{ base: "column", sm: "row" }} width={{ base: "100%", md: "auto" }}>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            onClick={() => {
              setEntity("Módulo");
              setIsEditing(false);
              setName("");
              onOpenModuleModal();
            }}
            size={buttonSize}
            mb={{ base: 2, sm: 0 }}
            mr={{ base: 0, sm: 2 }}
            width={{ base: "100%", sm: "auto" }}
          >
            Agregar Módulo
          </Button>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            onClick={() => {
              setSelectedModule(null);
              setEntity("Bloque");
              setIsEditing(false);
              setName("");
              onOpenModuleModal();
            }}
            size={buttonSize}
            width={{ base: "100%", sm: "auto" }}
          >
            Agregar Bloque
          </Button>
        </Flex>
      </Flex>

      <Flex justify="flex-end" mb={4}>
        <Button
          leftIcon={allExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={toggleAllAccordions}
          size="sm"
          variant="ghost"
          colorScheme="gray"
        >
          {allExpanded ? "Colapsar todos" : "Expandir todos"}
        </Button>
      </Flex>

      <Accordion allowMultiple index={expandedIndexes} onChange={(expandedIndexes) => setExpandedIndexes(expandedIndexes as number[])}>
        {modules.map((module, index) => (
          <Fade in={true} key={module.id} transition={{ enter: { duration: 0.1 }, exit: { duration: 0.3 } }}>
            <AccordionItem mb={4}>
              <h2>
                <Flex align="center" width="100%">
                  <AccordionButton flex="1">
                    <Box textAlign="left">
                      <Tag size="lg" variant="subtle" colorScheme="blue"  px={4} py={2}>
                        <Text fontSize="lg" fontWeight="bold">{module.name}</Text>
                      </Tag>
                    </Box>
                    <AccordionIcon ml="auto" />
                  </AccordionButton>
                  <Tooltip label="Editar Módulo">
                    <IconButton
                      aria-label="Editar Módulo"
                      icon={<EditIcon />}
                      size={iconButtonSize}
                      variant="ghost"
                      onClick={() => {
                        setSelectedModule(module);
                        setName(module.name);
                        setIsEditing(true);
                        setEntity("Módulo");
                        onOpenModuleModal();
                      }}
                      ml={2}
                    />
                  </Tooltip>
                  <Tooltip label="Eliminar Módulo">
                    <IconButton
                      aria-label="Eliminar Módulo"
                      icon={<DeleteIcon />}
                      size={iconButtonSize}
                      variant="ghost"
                      onClick={() => {
                        setModuleToDelete(module.id);
                        setIsDeleteAlertOpen(true);
                      }}
                      ml={2}
                    />
                  </Tooltip>
                </Flex>
              </h2>
              <AccordionPanel pb={4}>
                <Grid templateColumns={{ base: "1fr", md: "repeat(auto-fill, minmax(300px, 1fr))" }} gap={4}>
                  {module.blocks.map((block) => (
                    <GridItem key={block.id}>
                      <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        p={4}
                        bg="gray.50"
                        boxShadow="sm"
                        _hover={{ boxShadow: "md" }}
                        transition="all 0.2s"
                      >
                        <Flex justify="space-between" align="center" mb={3}>
                          <Tag size="md" variant="subtle" colorScheme="purple" borderRadius="full" px={3} py={1}>
                            <Text fontSize="md" fontWeight="semibold">{block.name}</Text>
                          </Tag>
                          <Flex>
                            <Tooltip label="Editar Bloque">
                              <IconButton
                                aria-label="Editar Bloque"
                                icon={<EditIcon />}
                                size={iconButtonSize}
                                variant="ghost"
                                onClick={() => {
                                  setSelectedModule(module);
                                  setSelectedBlock(block);
                                  setName(block.name);
                                  setIsEditing(true);
                                  setEntity("Bloque");
                                  onOpenModuleModal();
                                }}
                              />
                            </Tooltip>
                            <Tooltip label="Eliminar Bloque">
                              <IconButton
                                aria-label="Eliminar Bloque"
                                icon={<DeleteIcon />}
                                size={iconButtonSize}
                                variant="ghost"
                                ml={2}
                                onClick={() => handleDeleteBlock(module.id, block.id)}
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                        <VStack spacing={3} align="stretch" flex={1}>
                          {block.topics.map((topic) => (
                            <Box
                              key={topic.id}
                              borderWidth="1px"
                              borderRadius="md"
                              p={3}
                              bg="white"
                              boxShadow="sm"
                              _hover={{ boxShadow: "md" }}
                              transition="all 0.2s"
                            >
                              <VStack spacing={2} align="stretch">
                                <Flex align="center" justify="space-between">
                                  <Flex align="center">
                                    <Box mr={3}>
                                      <Tooltip label={`Estado: ${topic.status}`}>
                                        <Badge
                                          size="lg"
                                          fontSize="md"
                                          px={2}
                                          py={1}
                                          colorScheme={
                                            topic.status === "Completado"
                                              ? "green"
                                              : topic.status === "En Progreso"
                                              ? "blue"
                                              : "gray"
                                          }
                                          cursor="pointer"
                                          onClick={() => {
                                            const newStatus = topic.status === "Sin Empezar" ? "En Progreso" :
                                                              topic.status === "En Progreso" ? "Completado" : "Sin Empezar";
                                            handleStatusChange(module.id, block.id, topic.id, newStatus);
                                          }}
                                        >
                                          {topic.status === "Completado" ? "✓" : topic.status === "En Progreso" ? "⋯" : "○"}
                                        </Badge>
                                      </Tooltip>
                                    </Box>
                                    <Text fontSize="md" fontWeight="medium" isTruncated color={
                                      topic.status === "Completado" ? "green.500" :
                                      topic.status === "En Progreso" ? "blue.500" : "gray.500"
                                    }>
                                      {topic.name}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex justify="flex-end">
                                  <Tooltip label={topic.pdfUrl ? "Ver PDF" : "Subir PDF"}>
                                    <IconButton
                                      aria-label={topic.pdfUrl ? "Ver PDF" : "Subir PDF"}
                                      icon={topic.pdfUrl ? <ViewIcon /> : <AttachmentIcon />}
                                      size={iconButtonSize}
                                      variant="ghost"
                                      onClick={() => {
                                        if (topic.pdfUrl) {
                                          window.open(topic.pdfUrl, "_blank");
                                        } else {
                                          handleOpenPdfModal(topic);
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip label="Editar Tema">
                                    <IconButton
                                      aria-label="Editar Tema"
                                      icon={<EditIcon />}
                                      size={iconButtonSize}
                                      variant="ghost"
                                      onClick={() => {
                                        setSelectedModule(module);
                                        setSelectedBlock(block);
                                        setSelectedTopic(topic);
                                        setName(topic.name);
                                        setIsEditing(true);
                                        setEntity("Tema");
                                        onOpenModuleModal();
                                      }}
                                    />
                                  </Tooltip>
                                  {topic.pdfUrl && (
                                    <Tooltip label="Eliminar PDF">
                                      <IconButton
                                        aria-label="Eliminar PDF"
                                        icon={<CloseIcon />}
                                        size={iconButtonSize}
                                        variant="ghost"
                                        onClick={() => handleDeletePdf(topic)}
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip label="Eliminar Tema">
                                    <IconButton
                                      aria-label="Eliminar Tema"
                                      icon={<DeleteIcon />}
                                      size={iconButtonSize}
                                      variant="ghost"
                                      onClick={() => handleDeleteTopic(module.id, block.id, topic.id)}
                                    />
                                  </Tooltip>
                                </Flex>
                              </VStack>
                            </Box>
                          ))}
                        </VStack>
                        <Button
                          size={buttonSize}
                          leftIcon={<AddIcon />}
                          mt={3}
                          onClick={() => {
                            setSelectedModule(module);
                            setSelectedBlock(block);
                            setSelectedTopic(null);
                            setName("");
                            setIsEditing(false);
                            setEntity("Tema");
                            onOpenModuleModal();
                          }}
                        >
                          Agregar Tema
                        </Button>
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Fade>
        ))}
      </Accordion>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar Módulo
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro de que quieres eliminar este módulo? Esta acción no se puede deshacer.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={() => {
                if (moduleToDelete) {
                  handleDeleteModule(moduleToDelete);
                }
              }} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AddEditModal
        isOpen={isModuleModalOpen}
        onClose={onCloseModuleModal}
        entity={entity}
        name={name}
        setName={setName}
        isEditing={isEditing}
        handleAddOrUpdate={handleAddOrUpdate}
        modules={modules}
        selectedModuleId={selectedModuleId}
        setSelectedModuleId={setSelectedModuleId}
      />

      <PdfUploadModal
        isOpen={isPdfModalOpen}
        onClose={handleClosePdfModal}
        handlePdfUpload={handlePdfUpload}
      />
    </Box>
  );
};

export default React.memo(Modules);
