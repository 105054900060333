import React from "react";
import {
  Text,
  SimpleGrid,
  Card,
  IconButton,
  Flex,
  Box,
} from "@chakra-ui/react";
import { NewsFeedProps } from "../../types/news";
import { format, parse, isValid } from "date-fns";
import { AiOutlineEye } from "react-icons/ai";

const NewsFeed: React.FC<NewsFeedProps> = ({ newsItems }) => {
  const formatDate = (date: string) => {
    const parsedDate = parse(date, "yyyyMMdd", new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, "dd/MM/yyyy");
    }
    return "Fecha no válida";
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      {newsItems.map((item, index) => (
        <Card
          key={index}
          variant="outline"
          borderRadius="md"
          overflow="hidden"
          borderWidth="1px"
          boxShadow="sm"
          height="auto"
          p={3}
        >
          {item.data.data.sumario.diario.map((diario, i) => (
            <Box
              key={i}
              position="relative"
              mb={i !== item.data.data.sumario.diario.length - 1 ? 4 : 0}
            >
              <Flex
                direction={{ base: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ base: "flex-start", sm: "center" }}
              >
                <Box mb={{ base: 2, sm: 0 }}>
                  <Text fontSize="sm" fontWeight="bold">
                    {`BOE - ${formatDate(item.date)}`}
                  </Text>
                  <Text fontSize="sm">{`Diario Número: ${diario.numero}`}</Text>
                </Box>
                <IconButton
                  aria-label="Ver PDF"
                  icon={<AiOutlineEye />}
                  size="sm"
                  colorScheme="teal"
                  onClick={() =>
                    window.open(diario.sumario_diario.url_pdf.texto, "_blank")
                  }
                  position={{ base: "absolute", sm: "static" }}
                  top={{ base: 0, sm: "auto" }}
                  right={{ base: 0, sm: "auto" }}
                />
              </Flex>
            </Box>
          ))}
        </Card>
      ))}
    </SimpleGrid>
  );
};

export default NewsFeed;
