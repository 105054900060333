import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  SimpleGrid,
  HStack,
  useDisclosure,
  useToast,
  Flex,
  Fade,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { FaSort } from "react-icons/fa";
import TestCard from "../components/tests/TestCard";
import TestEditorModal from "../components/tests/TestEditorModal";
import TestCreatorModal from "../components/tests/TestCreatorModal";
import TestExecutorModal from "../components/tests/TestExecutorModal";
import TestImporterModal from "../components/tests/TestImporterModal";
import TestCardStatsModal from "../components/tests/TestCardStatsModal";
import TestPdfImporterModal from "../components/tests/TestPdfImporterModal";
import {
  getTests,
  deleteTest,
  updateTestWithQuestions,
  saveTestWithQuestions,
  getTestById,
  shareTest,
} from "../firebase/testOperations";
import { Test } from "../types/test";
import { useAuth } from "../hooks/useAuth";

const Tests: React.FC = () => {
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isExecutorOpen,
    onOpen: onExecutorOpen,
    onClose: onExecutorClose,
  } = useDisclosure();
  const {
    isOpen: isImportOpen,
    onOpen: onImportOpen,
    onClose: onImportClose,
  } = useDisclosure();
  const {
    isOpen: isStatsOpen,
    onOpen: onStatsOpen,
    onClose: onStatsClose,
  } = useDisclosure();
  const {
    isOpen: isPdfImportOpen,
    onOpen: onPdfImportOpen,
    onClose: onPdfImportClose,
  } = useDisclosure();

  const [tests, setTests] = useState<Test[]>([]);
  const [filteredTests, setFilteredTests] = useState<Test[]>([]);
  const [currentTest, setCurrentTest] = useState<Test | null>(null);
  const [currentTestId, setCurrentTestId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleTests, setVisibleTests] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [importedTests, setImportedTests] = useState<string[]>([]);
  const [statsUpdateFlag, setStatsUpdateFlag] = useState(0);
  const [sortOption, setSortOption] = useState<string>("titleAsc");

  const toast = useToast();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      fetchTests();
    }
  }, [currentUser]);

  useEffect(() => {
    const sorted = [...tests].sort((a, b) => {
      switch (sortOption) {
        case "titleAsc":
          return a.title.localeCompare(b.title);
        case "titleDesc":
          return b.title.localeCompare(a.title);
        case "questionsAsc":
          return a.questions.length - b.questions.length;
        case "questionsDesc":
          return b.questions.length - a.questions.length;
        default:
          return 0;
      }
    });
    setFilteredTests(sorted);
  }, [sortOption, tests]);

  const fetchTests = async () => {
    if (!currentUser) return;

    setIsLoading(true);
    try {
      const fetchedTests = await getTests(currentUser.uid);
      setTests(fetchedTests);
      setFilteredTests(fetchedTests);
      const initialVisibility = fetchedTests.reduce((acc, test) => {
        acc[test.id] = true;
        return acc;
      }, {} as { [key: string]: boolean });
      setVisibleTests(initialVisibility);
    } catch (error) {
      console.error("Error fetching tests:", error);
      toast({
        title: "Error al cargar los tests.",
        description:
          "No se pudieron cargar los tests. Por favor, intente de nuevo más tarde.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatsUpdate = async () => {
    if (currentTest && currentUser) {
      try {
        await fetchTests();
        setStatsUpdateFlag((prevFlag) => prevFlag + 1);
      } catch (error) {
        console.error("Error al actualizar las estadísticas", error);
      }
    }
  };

  const handleCreateTest = () => onCreateOpen();
  const handleImportTest = () => onImportOpen();
  const handleImportPDF = () => onPdfImportOpen();

  const handleSaveTest = async (test: Test) => {
    if (!currentUser) return;

    try {
      if (test.id) {
        await updateTestWithQuestions(currentUser.uid, test.id, test);
        // Update local state
        setTests(prevTests => prevTests.map(t => t.id === test.id ? test : t));
        setFilteredTests(prevTests => prevTests.map(t => t.id === test.id ? test : t));
        toast({
          title: "Test actualizado correctamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        return test; // Return the updated test
      } else {
        const savedTest = await saveTestWithQuestions(currentUser.uid, test);
        // Add new test to local state immediately
        setTests(prevTests => [...prevTests, savedTest]);
        setFilteredTests(prevTests => [...prevTests, savedTest]);
        // Set the new test as visible immediately
        setVisibleTests(prev => ({ ...prev, [savedTest.id]: true }));
        toast({
          title: "Test guardado correctamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        return savedTest; // Return the saved test
      }
      onEditClose();
    } catch (error) {
      toast({
        title: "Error al guardar el test.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditTest = async (test: Test) => {
    if (currentUser) {
      const fullTest = await getTestById(currentUser.uid, test.id);
      if (fullTest) {
        setCurrentTest(fullTest);
        // Update the local state with the latest data
        setTests(prevTests => prevTests.map(t => t.id === fullTest.id ? fullTest : t));
        setFilteredTests(prevTests => prevTests.map(t => t.id === fullTest.id ? fullTest : t));
        onEditOpen();
      }
    }
  };

  const handleDeleteTest = async (testId: string) => {
    if (!currentUser) return;

    try {
      setVisibleTests((prev) => ({ ...prev, [testId]: false }));

      setTimeout(async () => {
        await deleteTest(currentUser.uid, testId);
        setTests((prevTests) => prevTests.filter((test) => test.id !== testId));
        setFilteredTests((prevTests) =>
          prevTests.filter((test) => test.id !== testId)
        );
        toast({
          title: "Test eliminado correctamente.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }, 500);
    } catch (error) {
      toast({
        title: "Error al eliminar el test.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStartTest = (testId: string) => {
    setCurrentTestId(testId);
    onExecutorOpen();
  };

  const handleImportTests = async (importedTests: Test[]) => {
    if (!currentUser) return;

    try {
      const savePromises = importedTests.map((test) =>
        saveTestWithQuestions(currentUser.uid, test)
      );

      const savedTests = await Promise.all(savePromises);

      setTests((prevTests) => [...prevTests, ...savedTests]);
      setFilteredTests((prevTests) => [...prevTests, ...savedTests]);

      const newVisibleTests = savedTests.reduce((acc, test) => {
        acc[test.id] = false;
        return acc;
      }, {} as { [key: string]: boolean });

      setVisibleTests((prev) => ({ ...prev, ...newVisibleTests }));
      setImportedTests(savedTests.map((test) => test.id));

      setTimeout(() => {
        setVisibleTests((prev) => {
          const updated = { ...prev };
          savedTests.forEach((test) => {
            updated[test.id] = true;
          });
          return updated;
        });
      }, 100);

      toast({
        title: "Tests importados correctamente.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error importing tests:", error);
      toast({
        title: "Error al importar los tests.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePdfImport = async (importedTests: Test[]) => {
    if (!currentUser) return;

    try {
      const savePromises = importedTests.map((test) =>
        saveTestWithQuestions(currentUser.uid, test)
      );

      const savedTests = await Promise.all(savePromises);

      setTests((prevTests) => [...prevTests, ...savedTests]);
      setFilteredTests((prevTests) => [...prevTests, ...savedTests]);

      const newVisibleTests = savedTests.reduce((acc, test) => {
        acc[test.id] = true;
        return acc;
      }, {} as { [key: string]: boolean });

      setVisibleTests((prev) => ({ ...prev, ...newVisibleTests }));

      toast({
        title: "Tests importados correctamente desde PDF.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error importing tests from PDF:", error);
      toast({
        title: "Error al importar los tests desde PDF.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOpenStatsModal = (test: Test) => {
    setCurrentTest(test);
    onStatsOpen();
  };

  const handleShareTest = async (testId: string) => {
    try {
      if (currentUser) {
        await shareTest(currentUser.uid, testId, currentUser.displayName || '');
        setTests(tests.map(test => 
          test.id === testId ? { ...test, isShared: true } : test
        ));
      }
    } catch (error) {
      console.error('Error sharing test:', error);
    }
  };

  const renderTestCards = () => {
    if (isLoading) {
      return (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
          <Text fontSize="xl" fontWeight="medium" color="gray.600">
            Cargando tests...
          </Text>
        </Box>
      );
    }

    if (filteredTests.length === 0) {
      return (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
          <Box
            textAlign="center"
            p={6}
            borderRadius="lg"
            bg="gray.50"
            boxShadow="md"
            maxWidth="400px"
            width="100%"
          >
            <Text fontSize="xl" fontWeight="medium" color="gray.600">
              No hay tests que mostrar
            </Text>
            <Text fontSize="md" color="gray.500" mt={2}>
              Crea un nuevo test o importa uno para comenzar
            </Text>
          </Box>
        </Box>
      );
    }

    return filteredTests.map((test) => (
      <Fade
        key={test.id}
        in={visibleTests[test.id]}
        transition={{ enter: { duration: 0.1 }, exit: { duration: 0.3 } }}
      >
        <TestCard
          title={test.title}
          scenario={test.scenario || false}
          description={test.description}
          questionCount={test.questions ? test.questions.length : 0}
          onStart={() => handleStartTest(test.id)}
          onEdit={() => handleEditTest(test)}
          onDelete={() => handleDeleteTest(test.id)}
          onStats={() => handleOpenStatsModal(test)}
          onShare={() => handleShareTest(test.id)}
          isShared={test.isShared}
          stats={test.stats}
          isNewlyImported={importedTests.includes(test.id)}
        />
      </Fade>
    ));
  };

  return (
    <Box p={4} mt={2} maxW="1200px" mx="auto">
      <Flex direction={{ base: "column", md: "row" }} justify="space-between" align={{ base: "start", md: "center" }} mb={6} mt={4}>
        <Heading as="h1" size="lg" mb={{ base: 4, md: 0 }}>
          Tests
        </Heading>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Opciones de ordenamiento"
            icon={<FaSort />}
            variant="outline"
          />
          <MenuList>
            <MenuItem onClick={() => setSortOption("titleAsc")}>
              Título (A-Z)
            </MenuItem>
            <MenuItem onClick={() => setSortOption("titleDesc")}>
              Título (Z-A)
            </MenuItem>
            <MenuItem onClick={() => setSortOption("questionsAsc")}>
              Número de preguntas (Menor a Mayor)
            </MenuItem>
            <MenuItem onClick={() => setSortOption("questionsDesc")}>
              Número de preguntas (Mayor a Menor)
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex direction={{ base: "column", sm: "row" }} wrap="wrap" mb={6} gap={4}>
        <Button colorScheme="teal" onClick={handleCreateTest} width={{ base: "100%", sm: "auto" }}>
          Crear Test
        </Button>
        <Button colorScheme="blue" onClick={handleImportTest} width={{ base: "100%", sm: "auto" }}>
          Importar Test
        </Button>
        <Button colorScheme="purple" onClick={handleImportPDF} width={{ base: "100%", sm: "auto" }}>
          Importar PDF
        </Button>
      </Flex>
      <Fade in={!isLoading} transition={{ enter: { duration: 0.5 } }}>
        {filteredTests.length === 0 ? (
          renderTestCards()
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
            {renderTestCards()}
          </SimpleGrid>
        )}
      </Fade>
      <TestEditorModal
        isOpen={isEditOpen}
        onClose={onEditClose}
        test={currentTest}
        onSave={handleSaveTest}
      />
      <TestCreatorModal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        onSave={handleSaveTest}
        test={null}
        userId={currentUser?.uid || ""}
      />
      <TestExecutorModal
        isOpen={isExecutorOpen}
        onClose={onExecutorClose}
        testId={currentTestId || ""}
        onStatsUpdate={handleStatsUpdate}
      />
      <TestImporterModal
        isOpen={isImportOpen}
        onClose={onImportClose}
        onImport={handleImportTests}
      />
      <TestPdfImporterModal
        isOpen={isPdfImportOpen}
        onClose={onPdfImportClose}
        onImport={handlePdfImport}
      />
      {currentTest && currentUser && (
        <TestCardStatsModal
          isOpen={isStatsOpen}
          onClose={onStatsClose}
          title={currentTest.title}
          testId={currentTest.id}
          userId={currentUser.uid}
          key={`${currentTest.id}-${statsUpdateFlag}`}
        />
      )}
    </Box>
  );
};

export default Tests;
