import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  VStack,
  Text,
  useDisclosure,
  useToast,
  Heading,
  Stack,
  Link as ChakraLink,
  Fade,
} from "@chakra-ui/react";

import LinksModal from "../../src/components/links/LinksModal";
import { Link } from "../types/link";
import { saveLink, getLinks, deleteLink } from "../firebase/linkOperations";
import { useAuth } from "../hooks/useAuth";
import LinkCard from "../components/links/LinkCard";

const Links: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const [currentLink, setCurrentLink] = useState<Link | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchLinks = async () => {
      if (currentUser) {
        setIsLoading(true);
        try {
          const userLinks = await getLinks(currentUser.uid);
          setLinks(userLinks);
        } catch (error) {
          console.error("Error fetching links:", error);
          toast({
            title: "Error al cargar los enlaces",
            description:
              "No se pudieron cargar los enlaces. Por favor, intente de nuevo más tarde.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchLinks();
  }, [currentUser, toast]);

  const handleSave = async (link: Link) => {
    if (currentUser) {
      try {
        await saveLink(currentUser.uid, link);
        setLinks((prevLinks) =>
          prevLinks.some((l) => l.id === link.id)
            ? prevLinks.map((l) => (l.id === link.id ? link : l))
            : [...prevLinks, link]
        );
        toast({
          title: "Enlace guardado",
          description: "El enlace ha sido guardado con éxito.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        setCurrentLink(null);
      } catch (error) {
        toast({
          title: "Error al guardar",
          description: "No se pudo guardar el enlace. Inténtalo de nuevo.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleEdit = (link: Link) => {
    setCurrentLink(link);
    onOpen();
  };

  const handleDelete = async (id: string) => {
    if (currentUser) {
      try {
        await deleteLink(currentUser.uid, id);
        setLinks((prevLinks) => prevLinks.filter((link) => link.id !== id));
        toast({
          title: "Enlace eliminado",
          description: "El enlace ha sido eliminado con éxito.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error al eliminar",
          description: "No se pudo eliminar el enlace. Inténtalo de nuevo.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleVisit = (url: string) => {
    const formattedUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`;
    window.open(formattedUrl, "_blank");
  };

  const handleShare = (url: string) => {
    const shareText = `¡Mira este enlace! ${url}`;
    const whatsappShareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareText
    )}`;
    const emailShareURL = `mailto:?subject=Te recomiendo este enlace&body=${encodeURIComponent(
      shareText
    )}`;

    if (
      window.confirm(
        '¿Qué plataforma deseas usar para compartir? Presiona "Aceptar" para WhatsApp o "Cancelar" para correo electrónico.'
      )
    ) {
      window.open(whatsappShareURL, "_blank");
    } else {
      window.open(emailShareURL, "_blank");
    }
  };

  return (
    <Box p={8} mt={2} maxW="700px" mx="auto">
      <Flex justify="left" mb={6} mt={4}>
        <Heading as="h1" size="lg">
          Enlaces de interés
        </Heading>
      </Flex>
      <Flex mb={6} justify="flex-start">
        <Button
          colorScheme="teal"
          onClick={() => {
            setCurrentLink(null);
            onOpen();
          }}
        >
          Agregar Enlace
        </Button>
      </Flex>

      <Fade in={!isLoading} transition={{ enter: { duration: 0.5 } }}>
        <VStack spacing={3} align="stretch">
          {!isLoading && links.map((link) => (
            <LinkCard
              key={link.id}
              link={link}
              onVisit={handleVisit}
              onShare={handleShare}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </VStack>
      </Fade>

      <LinksModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setCurrentLink(null);
        }}
        onSave={handleSave}
        initialLink={currentLink || undefined}
      />
    </Box>
  );
};

export default Links;
