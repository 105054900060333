export const TOAST_MESSAGES = {
  MODULE: {
    CREATE: {
      title: "Módulo creado",
      description: (name: string) => `El módulo "${name}" ha sido creado con éxito.`,
    },
    UPDATE: {
      title: "Módulo editado",
      description: (name: string) => `El módulo "${name}" ha sido actualizado con éxito.`,
    },
    DELETE: {
      title: "Módulo eliminado",
      description: "El módulo ha sido eliminado con éxito.",
    },
    DELETE_ERROR: {
      title: "Error al eliminar el módulo",
      description: "Hubo un problema al eliminar el módulo. Por favor, inténtelo de nuevo.",
    },
  },
  BLOCK: {
    CREATE: {
      title: "Bloque creado",
      description: (name: string) => `El bloque "${name}" ha sido creado con éxito.`,
    },
    UPDATE: {
      title: "Bloque editado",
      description: (name: string) => `El bloque "${name}" ha sido actualizado con éxito.`,
    },
    DELETE: {
      title: "Bloque eliminado",
      description: "El bloque ha sido eliminado con éxito.",
    },
    DELETE_ERROR: {
      title: "Error al eliminar el bloque",
      description: "Hubo un problema al eliminar el bloque. Por favor, inténtelo de nuevo.",
    },
    NO_MODULE_SELECTED: {
      title: "No se puede agregar el bloque",
      description: "Por favor, seleccione un módulo antes de agregar un bloque.",
    },
  },
  TOPIC: {
    CREATE: {
      title: "Tema creado",
      description: (name: string) => `El tema "${name}" ha sido creado con éxito.`,
    },
    UPDATE: {
      title: "Tema editado",
      description: (name: string) => `El tema "${name}" ha sido actualizado con éxito.`,
    },
    DELETE: {
      title: "Tema eliminado",
      description: "El tema ha sido eliminado con éxito.",
    },
  },
  PDF: {
    UPLOAD: {
      title: "PDF subido",
      description: "El PDF ha sido subido con éxito.",
    },
    UPLOAD_ERROR: {
      title: "Error al subir PDF",
      description: "Hubo un error al subir el PDF. Por favor, inténtelo de nuevo.",
    },
    DELETE: {
      title: "PDF eliminado",
      description: "El PDF ha sido eliminado con éxito.",
    },
    DELETE_ERROR: {
      title: "Error al eliminar PDF",
      description: "Hubo un error al eliminar el PDF. Por favor, inténtelo de nuevo.",
    },
  },
  FETCH_ERROR: {
    title: "Error al cargar los módulos",
    description: "No se pudieron cargar los módulos. Por favor, intente de nuevo más tarde.",
  },
  INCOMPLETE_FIELDS: {
    title: "Campos incompletos",
    description: "Por favor, complete todos los campos requeridos.",
  },
};