import React, { useState, useEffect } from "react";
import { Box, Button, VStack, Text, Stack, Flex } from "@chakra-ui/react";
import { useAuth } from "../hooks/useAuth";
import { getCurrentUserFullName } from "../firebase/authOperations";

const Settings: React.FC = () => {
  // Estado inicial con 'Mi Perfil' abierto por defecto
  const [isProfileOpen, setProfileOpen] = useState(true);
  const [isSecurityOpen, setSecurityOpen] = useState(false);
  const [name, setName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);

  // Hook de autenticación para obtener el usuario actual
  const { currentUser } = useAuth();

  // Obtener el nombre y apellido del usuario
  useEffect(() => {
    const fetchFullName = async () => {
      if (currentUser) {
        const fullName = await getCurrentUserFullName();
        if (fullName) {
          setName(fullName.name);
          setLastName(fullName.lastName);
        }
      }
    };

    fetchFullName();
  }, [currentUser]);

  // Handlers para alternar secciones
  const handleProfileClick = () => {
    setProfileOpen(true);
    setSecurityOpen(false);
  };

  const handleSecurityClick = () => {
    setProfileOpen(false);
    setSecurityOpen(true);
  };

  return (
    <Flex p={4} maxW="6xl" mx="auto" direction="row">
      <Box w="1/4" bg="white" borderRadius="md" shadow="md" p={4} mr={4}>
        <Stack spacing={4}>
          <Button
            variant={isProfileOpen ? "solid" : "outline"}
            colorScheme="teal"
            onClick={handleProfileClick}
            width="100%"
          >
            Mi Perfil
          </Button>
          <Button
            variant={isSecurityOpen ? "solid" : "outline"}
            colorScheme="teal"
            onClick={handleSecurityClick}
            width="100%"
          >
            Seguridad
          </Button>
        </Stack>
      </Box>
      <Box
        w="full"
        bg="white"
        borderRadius="md"
        shadow="md"
        p={6} // Aumenta el padding para más espacio
      >
        {isProfileOpen && (
          <VStack spacing={4}>
            <Box w="full" p={2} borderRadius="md">
              <Text fontSize="md" mb={3}>
                <strong>Nombre:</strong> {name || "Cargando..."}
              </Text>
              <Text fontSize="md">
                <strong>Apellido:</strong> {lastName || "Cargando..."}
              </Text>
            </Box>
          </VStack>
        )}
        {isSecurityOpen && (
          <VStack spacing={4} align="start" w="full">
            <Button colorScheme="blue">Cambiar Contraseña</Button>
          </VStack>
        )}
      </Box>
    </Flex>
  );
};

export default Settings;
