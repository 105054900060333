import { db, storage } from "./firebaseConfig";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Module, Block, Topic } from "../types/module";

// Función para crear un nuevo módulo con bloques y temas
const createModule = async (
  userId: string,
  module: Module
): Promise<string> => {
  const moduleRef = doc(collection(db, "users", userId, "modules"));
  await setDoc(moduleRef, { name: module.name, order: module.order });

  const blocksRef = collection(moduleRef, "blocks");
  const batch = writeBatch(db);

  module.blocks.forEach((block) => {
    const blockRef = doc(blocksRef);
    batch.set(blockRef, { name: block.name });

    const topicsRef = collection(blockRef, "topics");
    block.topics.forEach((topic) => {
      const topicRef = doc(topicsRef);
      batch.set(topicRef, {
        name: topic.name,
        status: topic.status,
        pdfUrl: topic.pdfUrl || "",
      });
    });
  });

  await batch.commit();
  console.log("Module and blocks saved successfully");
  return moduleRef.id;
};

// Función para actualizar un módulo existente con sus bloques y temas
const updateModule = async (
  userId: string,
  moduleId: string,
  updatedModule: Module
): Promise<void> => {
  const moduleRef = doc(db, "users", userId, "modules", moduleId);
  await updateDoc(moduleRef, { name: updatedModule.name });

  const blocksRef = collection(moduleRef, "blocks");
  const batch = writeBatch(db);

  updatedModule.blocks.forEach((block) => {
    const blockRef = doc(blocksRef, block.id);
    batch.set(blockRef, { name: block.name });

    const topicsRef = collection(blockRef, "topics");
    block.topics.forEach((topic) => {
      const topicRef = doc(topicsRef, topic.id);
      batch.set(topicRef, {
        name: topic.name,
        status: topic.status,
        pdfUrl: topic.pdfUrl || "",
      });
    });
  });

  await batch.commit();
  console.log("Module and blocks updated successfully");
};

// Función para actualizar un bloque existente
const updateBlock = async (
  userId: string,
  moduleId: string,
  blockId: string,
  updatedBlock: Block
): Promise<void> => {
  const blockRef = doc(
    db,
    "users",
    userId,
    "modules",
    moduleId,
    "blocks",
    blockId
  );
  await updateDoc(blockRef, { name: updatedBlock.name });

  const topicsRef = collection(blockRef, "topics");
  const batch = writeBatch(db);

  updatedBlock.topics.forEach((topic) => {
    const topicRef = doc(topicsRef, topic.id);
    batch.set(topicRef, {
      name: topic.name,
      status: topic.status,
      pdfUrl: topic.pdfUrl || "",
    });
  });

  await batch.commit();
  console.log("Block updated successfully");
};

// Función para actualizar un tema existente
const updateTopic = async (
  userId: string,
  moduleId: string,
  blockId: string,
  topicId: string,
  updatedTopic: Topic
): Promise<void> => {
  const topicRef = doc(
    db,
    "users",
    userId,
    "modules",
    moduleId,
    "blocks",
    blockId,
    "topics",
    topicId
  );
  await updateDoc(topicRef, {
    name: updatedTopic.name,
    status: updatedTopic.status,
    pdfUrl: updatedTopic.pdfUrl || "",
  });
  console.log("Topic updated successfully");
};

// Función para eliminar un módulo junto con sus bloques y temas
const deleteModule = async (
  userId: string,
  moduleId: string
): Promise<void> => {
  const batch = writeBatch(db);
  const moduleRef = doc(db, "users", userId, "modules", moduleId);
  const blocksSnapshot = await getDocs(collection(moduleRef, "blocks"));

  for (const blockDoc of blocksSnapshot.docs) {
    const topicsSnapshot = await getDocs(collection(blockDoc.ref, "topics"));
    for (const topicDoc of topicsSnapshot.docs) {
      batch.delete(topicDoc.ref);
    }
    batch.delete(blockDoc.ref);
  }

  batch.delete(moduleRef);
  await batch.commit();
  console.log("Module and associated blocks and topics deleted successfully");
};

// Función para eliminar un bloque junto con sus temas
const deleteBlock = async (
  userId: string,
  moduleId: string,
  blockId: string
): Promise<void> => {
  const batch = writeBatch(db);
  const blockRef = doc(
    db,
    "users",
    userId,
    "modules",
    moduleId,
    "blocks",
    blockId
  );
  const topicsSnapshot = await getDocs(collection(blockRef, "topics"));

  for (const topicDoc of topicsSnapshot.docs) {
    batch.delete(topicDoc.ref);
  }

  batch.delete(blockRef);
  await batch.commit();
  console.log("Block and associated topics deleted successfully");
};

// Función para eliminar un tema
const deleteTopic = async (
  userId: string,
  moduleId: string,
  blockId: string,
  topicId: string
): Promise<void> => {
  const topicRef = doc(
    db,
    "users",
    userId,
    "modules",
    moduleId,
    "blocks",
    blockId,
    "topics",
    topicId
  );
  await deleteDoc(topicRef);
  console.log("Topic deleted successfully");
};

// Función para obtener todos los módulos de un usuario con sus bloques y temas
const getModules = async (userId: string): Promise<Module[]> => {
  const modulesRef = collection(db, "users", userId, "modules");
  const querySnapshot = await getDocs(modulesRef);
  const modules: Module[] = [];

  for (const modDoc of querySnapshot.docs) {
    const moduleData = modDoc.data();
    const blocksRef = collection(modDoc.ref, "blocks");
    const blocksSnapshot = await getDocs(blocksRef);
    const blocks: Block[] = [];

    for (const blockDoc of blocksSnapshot.docs) {
      const blockData = blockDoc.data();
      const topicsRef = collection(blockDoc.ref, "topics");
      const topicsSnapshot = await getDocs(topicsRef);
      const topics: Topic[] = [];

      topicsSnapshot.forEach((topicDoc) => {
        topics.push({ ...topicDoc.data(), id: topicDoc.id } as Topic);
      });

      blocks.push({
        id: blockDoc.id,
        name: blockData.name,
        topics,
      });
    }

    modules.push({
      id: modDoc.id,
      name: moduleData.name,
      blocks,
      order: moduleData.order || 0, // Add this line
    });
  }

  return modules;
};

// Función para subir un archivo PDF para un tema
const uploadTopicPdf = async (
  userId: string,
  topicId: string,
  file: File
): Promise<string> => {
  const storageRef = ref(
    storage,
    `users/${userId}/topics/${topicId}/${file.name}`
  );
  await uploadBytes(storageRef, file);
  const downloadUrl = await getDownloadURL(storageRef);
  return downloadUrl;
};

// Función para eliminar un archivo PDF para un tema
const deleteTopicPdf = async (pdfUrl: string): Promise<void> => {
  const pdfRef = ref(storage, pdfUrl);
  await deleteObject(pdfRef);
  console.log("PDF deleted successfully");
};

export {
  createModule,
  updateModule,
  updateBlock,
  updateTopic,
  deleteModule,
  deleteBlock,
  deleteTopic,
  getModules,
  uploadTopicPdf,
  deleteTopicPdf,
};
