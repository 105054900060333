// src/router.tsx
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./components/auth/PrivateRoute";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Tests from "./pages/Tests";
import Login from "./pages/Login";
import Links from "./pages/Links";
import Modules from "./pages/Modules";
import Signup from "./pages/Signup";
import VerifyEmail from "./pages/VerifyEmail";
import Settings from "./pages/Settings";
import CitationGenerator from "./pages/CitationGenerator";
import Community from "./pages/Community";

import { useAuth } from "./hooks/useAuth";

const AppRouter: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/login"
        element={currentUser ? <Navigate to="/home" /> : <Login />}
      />
      <Route path="/verify-email" element={<VerifyEmail />} />

      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<Home />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/tests" element={<Tests />} />
        <Route path="/community" element={<Community />} />
        <Route path="/links" element={<Links />} />
        <Route path="/citation" element={<CitationGenerator />} />

        <Route path="/settings" element={<Settings />} />
      </Route>

      <Route
        path="*"
        element={<Navigate to={currentUser ? "/home" : "/login"} />}
      />
    </Routes>
  );
};

export default AppRouter;
