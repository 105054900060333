// src/content/features.ts
import { IconType } from "react-icons";
import { FaCheckCircle } from "react-icons/fa";

interface Feature {
  title: string;
  description: string;
  icon: IconType;
}

const features: Feature[] = [
  {
    title: "Gestión de temario",
    description:
      "Organiza tus materias y temas de estudio de manera eficiente y personalizada.",
    icon: FaCheckCircle,
  },
  {
    title: "Progreso de estudio",
    description:
      "Sigue tu progreso de estudio con barras de progreso detalladas y objetivos claros.",
    icon: FaCheckCircle,
  },
  {
    title: "Calendario de Eventos",
    description:
      "Planifica tus sesiones de estudio y eventos importantes con nuestro calendario integrado.",
    icon: FaCheckCircle,
  },
  {
    title: "Noticias",
    description:
      "Mantente informado con las últimas noticias y actualizaciones relevantes para tus oposiciones.",
    icon: FaCheckCircle,
  },
  {
    title: "Subida de Documentos",
    description:
      "Sube y organiza documentos importantes para tener todo tu material de estudio en un solo lugar.",
    icon: FaCheckCircle,
  },
  {
    title: "Integración con ChatGPT",
    description:
      "Sube y organiza documentos importantes para tener todo tu material de estudio en un solo lugar.",
    icon: FaCheckCircle,
  },
];

export default features;
