import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Checkbox,
  Stack,
  Box,
  HStack,
  useToast,
  Text,
  Divider,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import { v4 as uuidv4 } from "uuid";
import {
  Test,
  Question,
  TestCreatorModalProps,
  TestStats,
} from "../../types/test";

const TestCreatorModal: React.FC<TestCreatorModalProps> = ({
  isOpen,
  onClose,
  test,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    isScenario: false,
    description: "",
    allowSkipQuestions: false,
    repeatIncorrectQuestions: false,
    enableTimer: false,
    questions: [] as Question[],
  });

  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    options: [
      { option: "", isCorrect: false },
      { option: "", isCorrect: false },
      { option: "", isCorrect: false },
      { option: "", isCorrect: false },
    ],
    index: null as number | null,
  });

  const toast = useToast();

  useEffect(() => {
    if (isOpen && test) {
      setFormData({
        title: test.title || "",
        isScenario: test.scenario || false,
        description: test.description || "",
        allowSkipQuestions: test.allowSkipQuestions || false,
        repeatIncorrectQuestions: test.repeatIncorrectQuestions || false,
        enableTimer: test.enableTimer || false,
        questions: test.questions || [],
      });
    } else {
      resetForm();
    }
  }, [isOpen, test]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleOptionChange = (index: number, value: string) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      options: prev.options.map((opt, i) =>
        i === index ? { ...opt, option: value } : opt
      ),
    }));
  };

  const handleOptionCheck = (index: number) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      options: prev.options.map((opt, i) => ({ ...opt, isCorrect: i === index })),
    }));
  };

  const handleAddQuestion = () => {
    if (!currentQuestion.text || !currentQuestion.options.some((opt) => opt.isCorrect)) {
      toast({
        title: "Por favor, completa todos los campos antes de añadir o actualizar la pregunta.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newQuestion: Question = {
      id: currentQuestion.index !== null ? formData.questions[currentQuestion.index].id : uuidv4(),
      question: currentQuestion.text,
      options: currentQuestion.options,
    };

    setFormData((prev) => ({
      ...prev,
      questions:
        currentQuestion.index !== null
          ? prev.questions.map((q, i) => (i === currentQuestion.index ? newQuestion : q))
          : [...prev.questions, newQuestion],
    }));

    resetQuestionForm();
  };

  const handleSave = async () => {
    if (formData.title.trim() === "" || (formData.isScenario && formData.description.trim() === "")) {
      toast({
        title: "Campos incompletos.",
        description: formData.isScenario
          ? "Por favor, completa el título y el enunciado del supuesto práctico."
          : "Por favor, completa el título del test.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const defaultStats: TestStats = {
      attempts: 0,
      averageScore: 0,
      bestScore: 0,
      totalQuestions: formData.questions.length,
    };

    const newTest: Test = {
      id: '', // Ensure this is an empty string for new tests
      title: formData.title,
      scenario: formData.isScenario,
      description: formData.description,
      allowSkipQuestions: formData.allowSkipQuestions,
      repeatIncorrectQuestions: formData.repeatIncorrectQuestions,
      enableTimer: formData.enableTimer,
      questions: formData.questions,
      stats: test?.stats || defaultStats,
      isShared: test?.isShared || false, // Add this line
      authorName: test?.authorName || '', // Add this line
      questionCount: formData.questions.length,
    };

    try {
      const savedTest = await onSave(newTest);
      resetForm();
      onClose();
      return savedTest; // Return the saved test
    } catch (error: unknown) {
      toast({
        title: "Error al guardar el test.",
        description: error instanceof Error ? error.message : "Error desconocido",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const resetQuestionForm = () => {
    setCurrentQuestion({
      text: "",
      options: [
        { option: "", isCorrect: false },
        { option: "", isCorrect: false },
        { option: "", isCorrect: false },
        { option: "", isCorrect: false },
      ],
      index: null,
    });
  };

  const resetForm = () => {
    setFormData({
      title: "",
      isScenario: false,
      description: "",
      allowSkipQuestions: false,
      repeatIncorrectQuestions: false,
      enableTimer: false,
      questions: [],
    });
    resetQuestionForm();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => { resetForm(); onClose(); }} size="xl">
      <ModalOverlay />
      <ModalContent maxH="90vh" overflowY="auto">
        <ModalHeader>Crear Test</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6}>
            <FormControl>
              <FormLabel>Título</FormLabel>
              <Input 
                name="title" 
                value={formData.title} 
                onChange={handleInputChange} 
                placeholder="Introduce el título del test"
              />
            </FormControl>
            <FormControl>
              <Checkbox name="isScenario" isChecked={formData.isScenario} onChange={handleCheckboxChange}>
                Supuesto práctico
              </Checkbox>
            </FormControl>
            <FormControl isRequired={formData.isScenario}>
              <FormLabel>{formData.isScenario ? "Enunciado del supuesto práctico" : "Descripción"}</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder={formData.isScenario ? "Introduce el enunciado del supuesto práctico" : "Descripción opcional del test"}
                height={formData.isScenario ? "200px" : "100px"}
              />
            </FormControl>
            {formData.isScenario && (
              <Text fontSize="sm" color="gray.500">
                El enunciado del supuesto práctico se mostrará constantemente durante la ejecución del test.
              </Text>
            )}
            <Stack direction={["column", "row"]} spacing={4} w="full" flexWrap="wrap">
              <FormControl flex="1" minW="200px">
                <Checkbox name="allowSkipQuestions" isChecked={formData.allowSkipQuestions} onChange={handleCheckboxChange}>
                  Permitir saltar preguntas
                </Checkbox>
              </FormControl>
              <FormControl flex="1" minW="200px">
                <Checkbox name="repeatIncorrectQuestions" isChecked={formData.repeatIncorrectQuestions} onChange={handleCheckboxChange}>
                  Repetir preguntas incorrectas
                </Checkbox>
              </FormControl>
              <FormControl flex="1" minW="200px">
                <Checkbox name="enableTimer" isChecked={formData.enableTimer} onChange={handleCheckboxChange}>
                  Habilitar temporizador (90 min)
                </Checkbox>
              </FormControl>
            </Stack>
            <Divider />
            <VStack spacing={4} w="full">
              <FormControl>
                <FormLabel>Enunciado</FormLabel>
                <Input
                  value={currentQuestion.text}
                  onChange={(e) => setCurrentQuestion((prev) => ({ ...prev, text: e.target.value }))}
                  placeholder="Texto de la pregunta"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Opciones</FormLabel>
                <Stack spacing={2}>
                  {currentQuestion.options.map((option, index) => (
                    <Box key={index} borderWidth="1px" borderRadius="md" p={2}>
                      <HStack spacing={2}>
                        <Input
                          value={option.option}
                          onChange={(e) => handleOptionChange(index, e.target.value)}
                          placeholder={`Opción ${index + 1}`}
                        />
                        <Checkbox
                          isChecked={option.isCorrect}
                          onChange={() => handleOptionCheck(index)}
                        >
                          Correcta
                        </Checkbox>
                      </HStack>
                    </Box>
                  ))}
                </Stack>
              </FormControl>
              <Button leftIcon={<AddIcon />}  colorScheme="teal" onClick={handleAddQuestion} w="full">
                {currentQuestion.index !== null ? "Actualizar Pregunta" : "Añadir Pregunta"}
              </Button>
            </VStack>
            {formData.questions.length > 0 && (
              <VStack spacing={4} w="full" mt={4}>
                <Text fontWeight="bold">Preguntas añadidas:</Text>
                {formData.questions.map((question, index) => (
                  <Box
                    key={question.id}
                    borderWidth="1px"
                    borderRadius="md"
                    p={4}
                    w="full"
                    cursor="pointer"
                    onClick={() => setCurrentQuestion({
                      text: question.question,
                      options: question.options,
                      index,
                    })}
                  >
                    <Text fontWeight="semibold">{question.question}</Text>
                    <Stack spacing={2} mt={2}>
                      {question.options.map((option, i) => (
                        <HStack key={i} spacing={2}>
                          <Text>{option.option}</Text>
                          {option.isCorrect && (
                            <Text color="green.500" fontWeight="bold">Correcta</Text>
                          )}
                        </HStack>
                      ))}
                    </Stack>
                  </Box>
                ))}
              </VStack>
            )}
            <HStack spacing={4} mt={4}>
              <Button colorScheme="teal" onClick={handleSave}>
                Guardar Test
              </Button>
              <Button colorScheme="gray" onClick={onClose}>
                Cancelar
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TestCreatorModal;
