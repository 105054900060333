import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  Box,
  Spinner,
  Fade,
} from "@chakra-ui/react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { TestCardStatsModalProps, TestStats } from "../../types/test";

const TestCardStatsModal: React.FC<TestCardStatsModalProps> = ({
  isOpen,
  onClose,
  title,
  testId,
  userId,
}) => {
  const [stats, setStats] = useState<TestStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTestStats = async () => {
      if (testId && userId) {
        setIsLoading(true);
        const testRef = doc(db, "users", userId, "tests", testId);
        const testDoc = await getDoc(testRef);
        const testData = testDoc.data();
        if (testData && testData.stats) {
          setStats(testData.stats);
        } else {
          setStats(null);
        }
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchTestStats(); // Always fetch the latest stats when the modal is opened
    }
  }, [isOpen, testId, userId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Spinner />
          ) : (
            <Fade in={!isLoading} transition={{ enter: { duration: 0.5 } }}>
              <VStack spacing={4} align="start">
                <Fade
                  in={true}
                  transition={{ enter: { duration: 0.3, delay: 0.1 } }}
                >
                  <Box>
                    <Text fontWeight="bold">Intentos:</Text>
                    <Text>{stats?.attempts ?? "No disponible"}</Text>
                  </Box>
                </Fade>
                <Fade
                  in={true}
                  transition={{ enter: { duration: 0.3, delay: 0.2 } }}
                >
                  <Box>
                    <Text fontWeight="bold">Media:</Text>
                    <Text>
                      {stats?.averageScore?.toFixed(2) ?? "No disponible"}
                    </Text>
                  </Box>
                </Fade>
                <Fade
                  in={true}
                  transition={{ enter: { duration: 0.3, delay: 0.3 } }}
                >
                  <Box>
                    <Text fontWeight="bold">Mejor Puntuación:</Text>
                    <Text>
                      {stats?.bestScore?.toFixed(2) ?? "No disponible"}
                    </Text>
                  </Box>
                </Fade>
              </VStack>
            </Fade>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TestCardStatsModal;
