import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Checkbox,
  Box,
  Flex,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  IconButton,
  ButtonGroup,
  Grid,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { Test, Question, TestEditorModalProps } from "../../types/test";
import { getTestById } from "../../firebase/testOperations";
import { useAuth } from "../../hooks/useAuth";
import { shareTest, unshareTest } from "../../firebase/testOperations";

const TestEditorModal: React.FC<TestEditorModalProps> = ({
  isOpen,
  onClose,
  test,
  onSave,
}) => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [allowSkipQuestions, setAllowSkipQuestions] = useState(false);
  const [repeatIncorrectQuestions, setRepeatIncorrectQuestions] = useState(false);
  const [enableTimer, setEnableTimer] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState<number | null>(null);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState(["", "", "", ""]);
  const [correctOptionIndex, setCorrectOptionIndex] = useState<number | null>(null);
  const [showNewQuestionForm, setShowNewQuestionForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;

  const toast = useToast();

  const [openAccordionItems, setOpenAccordionItems] = useState<number[]>([]);
  const [isShared, setIsShared] = useState(false);
  const [isScenario, setIsScenario] = useState(false);

  useEffect(() => {
    const fetchTestData = async () => {
      if (isOpen && test && currentUser) {
        setTitle(test.title || "");
        setDescription(test.description || "");
        setAllowSkipQuestions(test.allowSkipQuestions || false);
        setRepeatIncorrectQuestions(test.repeatIncorrectQuestions || false);
        setEnableTimer(test.enableTimer || false);
        setIsShared(test.isShared || false);
        setIsScenario(test.scenario || false);

        const fetchedTest = await getTestById(currentUser.uid, test.id);
        if (fetchedTest) {
          setQuestions(fetchedTest.questions);
          setIsShared(fetchedTest.isShared || false);
          setIsScenario(fetchedTest.scenario || false);
        }
      } else {
        resetForm();
      }
    };

    fetchTestData();
  }, [isOpen, test, currentUser]);

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setAllowSkipQuestions(false);
    setRepeatIncorrectQuestions(false);
    setEnableTimer(false);
    setQuestions([]);
    setEditingQuestionIndex(null);
    setIsShared(false);
    setIsScenario(false);
  };

  const handleSave = async () => {
    if (title.trim() === "") {
      toast({
        title: "Campos incompletos.",
        description: "Por favor, completa todos los campos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const updatedTest: Test = {
      id: test?.id || uuidv4(),
      title,
      description,
      allowSkipQuestions,
      repeatIncorrectQuestions,
      enableTimer,
      questions,
      stats: {
        ...test?.stats,
        totalQuestions: questions.length,
        attempts: test?.stats?.attempts || 0,
        averageScore: test?.stats?.averageScore || 0,
        bestScore: test?.stats?.bestScore || 0,
      },
      scenario: isScenario,
      isShared,
      authorName: currentUser?.displayName || 'Anonymous',
      questionCount: questions.length, // Add this line
    };

    try {
      await onSave(updatedTest);
      resetForm();
      onClose();
    } catch (error: unknown) {
      toast({
        title: "Error al guardar el test.",
        description: error instanceof Error ? error.message : "Error desconocido",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim() !== "" && newOptions.some(opt => opt.trim() !== "") && correctOptionIndex !== null) {
      const newQuestionObj = {
        id: uuidv4(),
        question: newQuestion,
        options: newOptions.map((option, index) => ({
          option,
          isCorrect: index === correctOptionIndex
        }))
      };
      setQuestions([...questions, newQuestionObj]);
      setNewQuestion("");
      setNewOptions(["", "", "", ""]);
      setCorrectOptionIndex(null);
    } else {
      toast({
        title: "Campos incompletos",
        description: "Por favor, completa la pregunta, al menos una opción, y selecciona la respuesta correcta.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditQuestion = (index: number) => {
    setEditingQuestionIndex(index);
  };

  const handleDeleteQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
    setEditingQuestionIndex(null);
  };

  const handleQuestionChange = (index: number, updatedQuestion: Question) => {
    setQuestions(questions.map((q, i) => (i === index ? updatedQuestion : q)));
  };

  const closeAccordionItem = (index: number) => {
    setOpenAccordionItems(prev => prev.filter(i => i !== index));
  };

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questions.slice(indexOfFirstQuestion, indexOfLastQuestion);
  const totalPages = Math.ceil(questions.length / questionsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleShareChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSharedState = e.target.checked;
    setIsShared(newSharedState);
    
    if (test && currentUser) {
      try {
        if (newSharedState) {
          await shareTest(currentUser.uid, test.id, test.id);
          toast({
            title: "Test compartido exitosamente",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          await unshareTest(currentUser.uid, test.id);
          toast({
            title: "Test dejado de compartir exitosamente",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error al cambiar el estado de compartir del test:", error);
        toast({
          title: newSharedState ? "Error al compartir el test" : "Error al dejar de compartir el test",
          description: "Por favor, intenta de nuevo más tarde.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsShared(!newSharedState);  // Revert the checkbox if the operation failed
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="80vh" overflowY="auto">
        <ModalHeader>Editar Test</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel>Título del Test</FormLabel>
              <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>{isScenario ? "Enunciado del supuesto práctico" : "Descripción del Test"}</FormLabel>
              <Textarea 
                value={description} 
                onChange={(e) => setDescription(e.target.value)}
                placeholder={isScenario ? "Introduce el enunciado del supuesto práctico" : "Descripción opcional del test"}
                height={isScenario ? "200px" : "100px"}
              />
            </FormControl>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Checkbox isChecked={isScenario} onChange={(e) => setIsScenario(e.target.checked)}>
                Supuesto práctico
              </Checkbox>
              <Checkbox isChecked={allowSkipQuestions} onChange={(e) => setAllowSkipQuestions(e.target.checked)}>
                Permitir saltar preguntas
              </Checkbox>
              <Checkbox isChecked={repeatIncorrectQuestions} onChange={(e) => setRepeatIncorrectQuestions(e.target.checked)}>
                Repetir preguntas incorrectas
              </Checkbox>
              <Checkbox isChecked={enableTimer} onChange={(e) => setEnableTimer(e.target.checked)}>
                Habilitar temporizador
              </Checkbox>
              <Checkbox isChecked={isShared} onChange={handleShareChange}>
                Compartir test
              </Checkbox>
            </Grid>
            <Box>
              <Flex justify="space-between" align="center" mb={4}>
                <Text fontSize="lg" fontWeight="bold">Preguntas</Text>
                <Button
                  rightIcon={showNewQuestionForm ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => setShowNewQuestionForm(!showNewQuestionForm)}
                  size="sm"
                >
                  Nueva Pregunta
                </Button>
              </Flex>
              {showNewQuestionForm && (
                <VStack spacing={3} align="stretch" mb={4}>
                  <FormControl>
                    <FormLabel>Nueva Pregunta</FormLabel>
                    <Input
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      placeholder="Escribe una nueva pregunta"
                    />
                  </FormControl>
                  {newOptions.map((option, index) => (
                    <Flex key={index} align="center">
                      <FormControl flex={1} mr={2}>
                        <Input
                          value={option}
                          onChange={(e) => {
                            const updatedOptions = [...newOptions];
                            updatedOptions[index] = e.target.value;
                            setNewOptions(updatedOptions);
                          }}
                          placeholder={`Opción ${index + 1}`}
                        />
                      </FormControl>
                      <Checkbox
                        isChecked={correctOptionIndex === index}
                        onChange={() => setCorrectOptionIndex(index)}
                      >
                        Correcta
                      </Checkbox>
                    </Flex>
                  ))}
                  <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={handleAddQuestion}>
                    Añadir Pregunta
                  </Button>
                </VStack>
              )}
              <Accordion allowMultiple index={openAccordionItems}>
                {currentQuestions.map((question, index) => (
                  <AccordionItem key={question.id}>
                    <AccordionButton onClick={() => {
                      setOpenAccordionItems(prev => 
                        prev.includes(index) 
                          ? prev.filter(i => i !== index)
                          : [...prev, index]
                      );
                    }}>
                      <Box flex="1" textAlign="left">
                        Pregunta {indexOfFirstQuestion + index + 1}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <QuestionEditor
                        question={question}
                        onSave={(updatedQuestion) => {
                          handleQuestionChange(indexOfFirstQuestion + index, updatedQuestion);
                          closeAccordionItem(index);
                        }}
                        onCancel={() => {
                          if (question.question === "" && question.options.length === 0) {
                            handleDeleteQuestion(indexOfFirstQuestion + index);
                          }
                          closeAccordionItem(index);
                        }}
                        onDelete={() => handleDeleteQuestion(indexOfFirstQuestion + index)}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
              <Box mt={6}  />
              <Flex p={4} mb={2} justify="space-between" align="center" mt={4}>
                <Button colorScheme="blue" onClick={onClose}>
                  Cancelar
                </Button>
                <Flex align="center">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    onClick={() => handlePageChange(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    aria-label="Previous page"
                    mr={2}
                  />
                  <Text>{currentPage} / {totalPages}</Text>
                  <IconButton
                    icon={<ChevronRightIcon />}
                    onClick={() => handlePageChange(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    aria-label="Next page"
                    ml={2}
                  />
                </Flex>
                <Button colorScheme="teal" onClick={handleSave}>
                  Guardar Test
                </Button>
              </Flex>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const QuestionEditor: React.FC<{
  question: Question;
  onSave: (updatedQuestion: Question) => void;
  onCancel: () => void;
  onDelete: () => void;
}> = ({ question, onSave, onCancel, onDelete }) => {
  const [editedQuestion, setEditedQuestion] = useState(question);

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...editedQuestion.options];
    updatedOptions[index] = { ...updatedOptions[index], option: value };
    setEditedQuestion({ ...editedQuestion, options: updatedOptions });
  };

  const handleCorrectOptionChange = (index: number) => {
    const updatedOptions = editedQuestion.options.map((opt, i) => ({
      ...opt,
      isCorrect: i === index,
    }));
    setEditedQuestion({ ...editedQuestion, options: updatedOptions });
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Enunciado</FormLabel>
        <Input
          value={editedQuestion.question}
          onChange={(e) => setEditedQuestion({ ...editedQuestion, question: e.target.value })}
        />
      </FormControl>
      {editedQuestion.options.map((option, index) => (
        <Flex key={index} align="center">
          <Input
            value={option.option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            mr={2}
          />
          <Checkbox
            isChecked={option.isCorrect}
            onChange={() => handleCorrectOptionChange(index)}
          >
            Correcta
          </Checkbox>
        </Flex>
      ))}
      <Flex justify="center">
        <ButtonGroup size="sm" spacing={3}>
        <Button colorScheme="blue" onClick={onCancel}>
            Cancelar
          </Button>
          <Button variant="outline" colorScheme="red" onClick={onDelete}>
            Borrar Pregunta
          </Button>

          <Button colorScheme="teal" onClick={() => onSave(editedQuestion)}>
            Guardar Pregunta
          </Button>
        </ButtonGroup>
      </Flex>
    </VStack>
  );
};

export default TestEditorModal;
