import { auth as firebaseAuth, db } from "./firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  applyActionCode,
  UserCredential,
  getAuth,
  deleteUser,
  onAuthStateChanged,
} from "firebase/auth";
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  deleteDoc as deleteDocFirestore,
} from "firebase/firestore";

// Función para registrarse y enviar el correo de verificación
export const signup = async (
  email: string,
  password: string,
  name: string,
  lastName: string
): Promise<UserCredential> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    const user = userCredential.user;

    await setDoc(doc(db, "users", user.uid), {
      email,
      name,
      lastName,
      isVerified: false,
      createdAt: new Date(),
    });

    await sendEmailVerification(user);

    return userCredential;
  } catch (error) {
    console.error("Error during signup:", error);
    throw error;
  }
};

// Función para iniciar sesión
export const login = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  try {
    return await signInWithEmailAndPassword(firebaseAuth, email, password);
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

// Función para cerrar sesión
export const logout = async (): Promise<void> => {
  try {
    await signOut(firebaseAuth);
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

// Función para verificar el correo electrónico y actualizar el estado de verificación
export const verifyAndCheckUser = async (oobCode?: string): Promise<void> => {
  try {
    const auth = getAuth();
    if (oobCode) {
      await applyActionCode(auth, oobCode);
    }
    // Recargar el usuario para actualizar el estado
    await auth.currentUser?.reload();
    const user = auth.currentUser;

    if (user && user.emailVerified) {
      // Actualizar el campo 'isVerified' en Firestore
      await updateDoc(doc(db, "users", user.uid), {
        isVerified: true,
      });
      console.log(`User ${user.uid} isVerified field updated to true.`);
    } else {
      console.error("Error: No current user available or email not verified.");
    }
  } catch (error) {
    console.error("Error during email verification:", error);
    throw error;
  }
};

// Listener para cambios en el estado de autenticación y actualización del campo isVerified
onAuthStateChanged(firebaseAuth, async (user) => {
  if (user) {
    await verifyAndCheckUser();
  }
});

// Función para verificar si el usuario está autenticado y si su correo está verificado
export const checkUserVerification = async (): Promise<boolean> => {
  try {
    const user = firebaseAuth.currentUser;

    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        return userData.isVerified === true;
      }
    }
    return false;
  } catch (error) {
    console.error("Error checking user verification:", error);
    return false;
  }
};

// Función para actualizar información del usuario en Firestore
export const updateUser = async (
  uid: string,
  updates: Partial<{ email: string }>
): Promise<void> => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, updates);
    console.log("User data updated successfully");
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};

// Función para eliminar un usuario de Firebase Authentication y Firestore
export const deleteUserAccount = async (uid: string): Promise<void> => {
  try {
    const userRef = doc(db, "users", uid);

    const user = firebaseAuth.currentUser;
    if (user && user.uid === uid) {
      await deleteUser(user);
    }

    await deleteDocFirestore(userRef);
    console.log("User account deleted successfully");
  } catch (error) {
    console.error("Error deleting user account:", error);
    throw error;
  }
};

// Función para obtener el nombre y apellido del usuario actual
export const getCurrentUserFullName = async (): Promise<{
  name: string;
  lastName: string;
} | null> => {
  try {
    const user = firebaseAuth.currentUser;

    if (user) {
      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        return { name: userData.name, lastName: userData.lastName };
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting user full name:", error);
    return null;
  }
};
