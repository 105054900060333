// src/pages/Landing.tsx
import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Icon,
  Grid,
  GridItem,
  Image,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";

import features from "../content/features";
import plans from "../content/plans";
import texts from "../content/texts";

const Landing: React.FC = () => {
  return (
    <>
      <Box
        as="section"
        bg="teal.500"
        color="white"
        py={{ base: "10", md: "20" }}
        textAlign="center"
        width="100%"
      >
        <Box maxW="container.lg" mx="auto">
          <Heading as="h1" size="2xl" mb="4">
            {texts.welcome.heading}
          </Heading>
          <Text fontSize="xl" mb="8">
            {texts.welcome.description}
          </Text>
          <Button
            as={RouterLink}
            to="/signup"
            size="lg"
            colorScheme="teal"
            bg="white"
            color="teal.500"
            _hover={{ bg: "teal.100" }}
            mb="4"
          >
            {texts.welcome.signupButton}
          </Button>
          <Text fontSize="md" color="white">
            {texts.welcome.loginPrompt}{" "}
            <Link
              as={RouterLink}
              to="/login"
              color="white"
              textDecoration="underline"
            >
              {texts.welcome.loginLink}
            </Link>
          </Text>
        </Box>
      </Box>

      <Box
        as="section"
        py={{ base: "10", md: "20" }}
        bg="gray.100"
        width="100%"
      >
        <Box maxW="container.lg" mx="auto" px="6">
          <Heading as="h2" size="xl" mb="12" textAlign="center">
            Funcionalidades Principales
          </Heading>
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8}>
            {features.map((feature, index) => (
              <GridItem
                key={index}
                bg="white"
                p="6"
                borderRadius="md"
                shadow="md"
              >
                <Flex align="center" mb="4">
                  <Icon as={feature.icon} w={8} h={8} color="teal.500" />
                  <Heading as="h3" size="md" ml="4">
                    {feature.title}
                  </Heading>
                </Flex>
                <Text>{feature.description}</Text>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box
        as="section"
        py={{ base: "10", md: "20" }}
        textAlign="center"
        bg="white"
        width="100%"
      >
        <Box maxW="container.lg" mx="auto" px="6">
          <Heading as="h2" size="xl" mb="6">
            {texts.whyChooseUs.heading}
          </Heading>
          <Text fontSize="lg" mb="8">
            {texts.whyChooseUs.description}
          </Text>
          <Image
            src="https://via.placeholder.com/800x400"
            alt="Screenshot de MisOpos"
            borderRadius="md"
            shadow="md"
            mx="auto"
          />
        </Box>
      </Box>

      <Box
        as="section"
        py={{ base: "10", md: "20" }}
        textAlign="center"
        bg="gray.100"
        width="100%"
      >
        <Box maxW="container.lg" mx="auto" px="6">
          <Heading as="h2" size="xl" mb="12">
            Planes de Precios
          </Heading>
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8}>
            {plans.map((plan, index) => (
              <GridItem
                key={index}
                bg="white"
                p="6"
                borderRadius="md"
                shadow="md"
              >
                <Heading as="h3" size="lg" mb="4">
                  {plan.name}
                </Heading>
                <Text fontSize="xl" fontWeight="bold" mb="4">
                  {plan.price}
                </Text>
                <Text mb="4">{plan.description}</Text>
                <Box textAlign="left">
                  {plan.features.map((feature, idx) => (
                    <Flex align="center" key={idx} mb="2">
                      <Icon
                        as={CheckCircleIcon}
                        w={5}
                        h={5}
                        color="teal.500"
                        mr="2"
                      />
                      <Text>{feature}</Text>
                    </Flex>
                  ))}
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box
        as="section"
        py={{ base: "10", md: "20" }}
        textAlign="center"
        bg="white"
        width="100%"
      >
        <Box maxW="container.lg" mx="auto" px="6">
          <Heading as="h2" size="xl" mb="6">
            {texts.openSource.heading}
          </Heading>
          <Text fontSize="lg" mb="8">
            {texts.openSource.description}
          </Text>
          <Button
            as="a"
            href="https://github.com/serjlc/misopos"
            target="_blank"
            rel="noopener noreferrer"
            size="lg"
            colorScheme="teal"
            bg="teal.500"
            color="white"
            _hover={{ bg: "teal.600" }}
          >
            {texts.openSource.button}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Landing;
