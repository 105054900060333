import React from "react";
import {
  IconButton,
  Card,
  SimpleGrid,
  Text,
  Flex,
  Box,
  Badge,
} from "@chakra-ui/react";
import { CalendarEventsProps } from "../../types/cal";
import { format, parseISO, isValid } from "date-fns";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

const CalendarEventsCard: React.FC<CalendarEventsProps> = ({
  events,
  onEdit,
  onDelete,
}) => {
  const formatDate = (date: string | Date) => {
    let parsedDate;
    if (typeof date === "string") {
      parsedDate = parseISO(date);
    } else {
      parsedDate = date;
    }
    if (isValid(parsedDate)) {
      return format(parsedDate, "dd/MM/yyyy");
    }
    return "Fecha no válida";
  };

  return (
    <Box>
      {events.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          {events.map((event, index) => (
            <Card
              key={index}
              variant="outline"
              borderRadius="md"
              overflow="hidden"
              borderWidth="1px"
              boxShadow="sm"
              height="auto"
              p={3}
              position="relative"
            >
              <Flex
                direction={{ base: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ base: "flex-start", sm: "center" }}
              >
                <Box>
                  <Badge
                    colorScheme="teal"
                    borderRadius="md"
                    fontSize="xs"
                    padding="0.2rem 0.4rem"
                    mb={2}
                  >
                    {formatDate(event.date)}
                  </Badge>
                  <Text fontSize="md" fontWeight="bold">
                    {event.title}
                  </Text>
                </Box>
                <Flex mt={{ base: 2, sm: 0 }}>
                  <IconButton
                    aria-label="Editar evento"
                    icon={<AiOutlineEdit />}
                    size="xs"
                    colorScheme="teal"
                    mr={2}
                    onClick={() => onEdit(event)}
                  />
                  <IconButton
                    aria-label="Eliminar evento"
                    icon={<AiOutlineDelete />}
                    size="xs"
                    colorScheme="red"
                    onClick={() => onDelete(event)}
                  />
                </Flex>
              </Flex>
            </Card>
          ))}
        </SimpleGrid>
      ) : (
        <Flex justifyContent="center" alignItems="center" minHeight="250px">
          <Box
            textAlign="center"
            p={6}
            borderRadius="lg"
            bg="gray.50"
            boxShadow="md"
            maxWidth="400px"
            width="100%"
          >
            <Text fontSize="xl" fontWeight="medium" color="gray.600">
              No hay eventos que mostrar
            </Text>
            <Text fontSize="md" color="gray.500" mt={2}>
              Agrega un nuevo evento para comenzar
            </Text>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CalendarEventsCard;
