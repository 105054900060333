import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Spinner,
    Stack,
    Text,
    useBreakpointValue
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FaClock, FaBook } from "react-icons/fa";
import { getTestById, updateTestStats } from "../../firebase/testOperations";
import { useAuth } from "../../hooks/useAuth";
import { Test, TestExecutorModalProps } from "../../types/test";

const TestExecutorModal: React.FC<TestExecutorModalProps> = ({
    isOpen,
    onClose,
    testId,
}) => {
    const [test, setTest] = useState<Test | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState<number[]>([]);
    const [checked, setChecked] = useState<boolean | null>(null);
    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
    const [incorrectQuestions, setIncorrectQuestions] = useState<number[]>([]);
    const [skippedQuestions, setSkippedQuestions] = useState<number[]>([]);
    const [questionsToAnswer, setQuestionsToAnswer] = useState<number[]>([]);
    const [timer, setTimer] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [correctOptionIndex, setCorrectOptionIndex] = useState<number | null>(
        null
    );
    const isMobile = useBreakpointValue({ base: true, md: false });

    const { currentUser } = useAuth();

    useEffect(() => {
        if (isOpen) {
            setTest(null);
            setCurrentQuestionIndex(0);
            setAnswers([]);
            setChecked(null);
            setIsCorrect(null);
            setIncorrectQuestions([]);
            setSkippedQuestions([]);
            setQuestionsToAnswer([]);
            setTimer(0);
            setIsLoading(true);
            setCorrectOptionIndex(null);
        }
    }, [isOpen]);

    useEffect(() => {
        const fetchTest = async () => {
            if (currentUser && isOpen && testId) {
                setIsLoading(true);
                const fetchedTest = await getTestById(currentUser.uid, testId);
                if (fetchedTest) {
                    setTest(fetchedTest);
                    setAnswers(Array(fetchedTest.questions.length).fill(-1));
                    setQuestionsToAnswer([...Array(fetchedTest.questions.length).keys()]);
                    if (fetchedTest.enableTimer) {
                        setTimer(90 * 60);
                    }
                }
                setIsLoading(false);
            }
        };

        if (isOpen && testId) {
            fetchTest();
        }
    }, [isOpen, testId, currentUser]);

    useEffect(() => {
        let timerInterval: NodeJS.Timeout | null = null;

        if (isOpen && test && test.enableTimer) {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 0) {
                        if (timerInterval) {
                            clearInterval(timerInterval);
                        }
                        handleTimeUp();
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => {
            if (timerInterval) {
                clearInterval(timerInterval);
            }
        };
    }, [isOpen, test]);

    const handleTimeUp = () => {
        setQuestionsToAnswer([]);
        setCurrentQuestionIndex(test!.questions.length);
    };

    const handleAnswerChange = (value: string) => {
        const newAnswers = [...answers];
        newAnswers[questionsToAnswer[currentQuestionIndex]] = parseInt(value);
        setAnswers(newAnswers);
    };

    const handleCheckAnswer = useCallback(() => {
        if (test && checked === null) {
            const questionIndex = questionsToAnswer[currentQuestionIndex];
            const correctIndex = test.questions[questionIndex].options.findIndex(
                (opt) => opt.isCorrect
            );
            setCorrectOptionIndex(correctIndex);
            const isAnswerCorrect = answers[questionIndex] === correctIndex;
            setIsCorrect(isAnswerCorrect);
            setChecked(true);

            if (!isAnswerCorrect) {
                setIncorrectQuestions((prev) => [...prev, questionIndex]);
            }
        }
    }, [test, checked, questionsToAnswer, currentQuestionIndex, answers]);

    const handleNextQuestion = useCallback(() => {
        if (checked !== null) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setChecked(null);
            setIsCorrect(null);
            setCorrectOptionIndex(null);
        } else if (test?.allowSkipQuestions) {
            handleSkipQuestion();
        }
    }, [checked, test?.allowSkipQuestions]);

    const handleSkipQuestion = () => {
        if (test?.allowSkipQuestions) {
            const questionIndex = questionsToAnswer[currentQuestionIndex];
            setSkippedQuestions((prev) => [...prev, questionIndex]);
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setChecked(null);
            setIsCorrect(null);
            setCorrectOptionIndex(null);
        }
    };

    const calculateResults = () => {
        if (!test) return { correctAnswers: 0, totalQuestions: 0 };

        const correctAnswers = test.questions.reduce(
            (acc, _, index) =>
                test.questions[index].options.findIndex((opt) => opt.isCorrect) ===
                    answers[index]
                    ? acc + 1
                    : acc,
            0
        );

        const totalQuestions = test.questions.length;

        return {
            correctAnswers,
            totalQuestions,
        };
    };

    useEffect(() => {
        if (test && currentQuestionIndex >= questionsToAnswer.length) {
            const questionsToRepeat = [
                ...(test.repeatIncorrectQuestions ? incorrectQuestions : []),
                ...skippedQuestions,
            ];

            if (questionsToRepeat.length > 0) {
                setQuestionsToAnswer(questionsToRepeat);
                setCurrentQuestionIndex(0);
                setIncorrectQuestions([]);
                setSkippedQuestions([]);
            } else {
                setCurrentQuestionIndex(test.questions.length);
                updateTestStatistics();
            }
        }
    }, [
        currentQuestionIndex,
        test,
        incorrectQuestions,
        skippedQuestions,
        questionsToAnswer,
    ]);

    const updateTestStatistics = async () => {
        if (currentUser && test) {
            const { correctAnswers, totalQuestions } = calculateResults();
            const score = (correctAnswers / totalQuestions) * 100;

            try {
                await updateTestStats(currentUser.uid, test.id, score);
                console.log("Estadísticas actualizadas con éxito");
            } catch (error) {
                console.error("Error al actualizar las estadísticas", error);
            }
        }
    };

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                if (checked === null) {
                    if (answers[questionsToAnswer[currentQuestionIndex]] !== -1) {
                        handleCheckAnswer();
                    } else if (test?.allowSkipQuestions) {
                        handleSkipQuestion();
                    }
                } else {
                    handleNextQuestion();
                }
            }
        };

        if (isOpen && test && currentQuestionIndex < questionsToAnswer.length) {
            window.addEventListener("keydown", handleKeyPress);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [
        isOpen,
        test,
        currentQuestionIndex,
        questionsToAnswer.length,
        checked,
        handleCheckAnswer,
        handleNextQuestion,
        answers,
    ]);

    const renderDescription = (description: string) => {
        const paragraphs = description.split("\n\n");
        return (
            <Accordion defaultIndex={[0]} allowToggle>
                {" "}
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Ver enunciado del supuesto práctico
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {paragraphs.map((paragraph, index) => (
                            <Text key={index} mb={4}>
                                {paragraph}
                            </Text>
                        ))}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        );
    };

    const results = calculateResults();
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent p={{ base: 2, md: 4 }}>
                <Flex
                    direction={{ base: "column", md: "row" }}
                    justify="space-between"
                    alignItems={{ base: "center", md: "center" }}
                    bg="teal"
                    p={{ base: 3, md: 4 }}
                    borderRadius="md"
                    boxShadow="md"
                    mb={2}
                    position="relative"
                >
                    {test?.enableTimer && (
                        <Flex
                            align="center"
                            p={{ base: 1, md: 2 }}
                            mb={{ base: 2, md: 0 }}
                            width={{ base: "auto", md: "auto" }}
                            justifyContent={{ base: "center", md: "flex-start" }}
                            order={{ base: 2, md: 1 }}
                        >
                            <Box as={FaClock} color="white" mr={2} mb={1} fontSize={{ base: "lg", md: "xl" }} />
                            <Text fontWeight="bold" color="white" fontSize={{ base: "lg", md: "xl" }}>
                                {formatTime(timer)}
                            </Text>
                        </Flex>
                    )}

                    <Flex
                        justify="center"
                        alignItems="center"
                        flex="1"
                        height="50px"
                        width={{ base: "100%", md: "auto" }}
                        order={{ base: 1, md: 2 }}
                    >
                        <Box as={FaBook} color="white" mr={2} mb={1} />
                        <Text
                            noOfLines={1}
                            textAlign="center"
                            fontSize={{ base: "md", md: "xl" }}
                            fontWeight="bold"
                            color="white"
                        >
                            {isLoading
                                ? "Cargando..."
                                : test
                                    ? test.title
                                    : "Test no encontrado"}
                        </Text>
                    </Flex>

                    <ModalCloseButton
                        position={{ base: "absolute", md: "static" }}
                        top={{ base: 2, md: "auto" }}
                        right={{ base: 2, md: "auto" }}
                        size={isMobile ? "sm" : "xl"}
                        color="white"
                        order={3}
                        p={2}
                    />
                </Flex>
                <ModalBody>
                    {isLoading ? (
                        <Flex justify="center" align="center" height="50vh">
                            <Spinner size="xl" />
                        </Flex>
                    ) : test && currentQuestionIndex < questionsToAnswer.length ? (
                        <Flex direction="column" alignItems="flex-start">
                            {test.scenario && renderDescription(test.description)}
                            <Text fontSize="xl" mt={6} mb={6} textAlign="left">
                                {
                                    test.questions[questionsToAnswer[currentQuestionIndex]]
                                        .question
                                }
                            </Text>
                            <RadioGroup
                                value={
                                    answers[questionsToAnswer[currentQuestionIndex]]?.toString() ??
                                    ""
                                }
                                onChange={(value) => handleAnswerChange(value)}
                                isDisabled={checked !== null}
                            >
                                <Stack direction="column" spacing={4} alignItems="flex-start">
                                    {test.questions[questionsToAnswer[currentQuestionIndex]].options.map(
                                        (option, optionIndex) => (
                                            <Flex key={optionIndex} alignItems="center">
                                                <Radio value={optionIndex.toString()} fontSize="xl">
                                                    {option.option}
                                                </Radio>
                                                {checked !== null &&
                                                    !isCorrect &&
                                                    optionIndex === correctOptionIndex && (
                                                        <Badge colorScheme="green" p={1} ml={4} fontSize="sm">
                                                            Correcto
                                                        </Badge>
                                                    )}
                                            </Flex>
                                        )
                                    )}
                                </Stack>
                            </RadioGroup>
                            {checked !== null && (
                                <Text
                                    mt={6}
                                    fontSize="xl"
                                    fontWeight="bold"
                                    color={isCorrect ? "green.500" : "red.500"}
                                    textAlign="left"
                                >
                                    {isCorrect ? "Correcto!" : "Incorrecto!"}
                                </Text>
                            )}
                        </Flex>
                    ) : test ? (
                        <Box
                            height="50vh"
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text fontSize="2xl">
                                Resultados: {results.correctAnswers} / {results.totalQuestions}
                            </Text>
                            <Text fontSize="2xl">
                                Porcentaje:{" "}
                                {((results.correctAnswers / results.totalQuestions) * 100).toFixed(2)}
                                %
                            </Text>
                        </Box>
                    ) : (
                        <Text fontSize="2xl" textAlign="center">Test no encontrado</Text>
                    )}
                </ModalBody>

                <ModalFooter>
                    {test && currentQuestionIndex < questionsToAnswer.length ? (
                        <Flex justifyContent="center" width="100%">
                            <Stack
                                direction={{ base: "column", md: "row" }}
                                spacing={{ base: 3, md: 6 }}
                                width="100%"
                                maxW={{ base: "100%", md: "400px" }}
                            >
                                {test.allowSkipQuestions && (
                                    <Button
                                        colorScheme="blue"
                                        size={isMobile ? "sm" : "lg"}
                                        width="100%"
                                        onClick={handleSkipQuestion}
                                    >
                                        Saltar
                                    </Button>
                                )}
                                <Button
                                    colorScheme="teal"
                                    size={isMobile ? "sm" : "lg"}
                                    width="100%"
                                    onClick={handleCheckAnswer}
                                    isDisabled={
                                        checked !== null ||
                                        answers[questionsToAnswer[currentQuestionIndex]] === -1
                                    }
                                >
                                    Comprobar
                                </Button>
                                <Button
                                    colorScheme="teal"
                                    size={isMobile ? "sm" : "lg"}
                                    width="100%"
                                    onClick={handleNextQuestion}
                                    isDisabled={checked === null}
                                >
                                    Siguiente
                                </Button>
                            </Stack>
                        </Flex>
                    ) : null}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default TestExecutorModal;
