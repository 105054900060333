const texts = {
  welcome: {
    heading: "Bienvenido a MisOpos",
    description:
      "La plataforma para organizar y gestionar tu estudio de oposiciones.",
    signupButton: "Regístrate Gratis",
    loginPrompt: "¿Ya tienes una cuenta?",
    loginLink: "Accede aquí",
  },
  whyChooseUs: {
    heading: "¿Por qué elegir MisOpos?",
    description:
      "MisOpos está diseñado para hacer tu preparación de oposiciones más eficiente y organizada. Con nuestras herramientas intuitivas, puedes centrarte en lo que realmente importa: estudiar y aprobar.",
  },
  openSource: {
    heading: "MisOpos es Open Source",
    description:
      "MisOpos está licenciado bajo la Licencia Pública General Affero (AGPL). Esto significa que puedes usar, modificar y distribuir el código sin necesidad de suscribirte a ningún plan, siempre y cuando mantengas la licencia AGPL.",
    button: "Explorar el Código Fuente",
  },
};

export default texts;
