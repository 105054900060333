import React, { useState } from "react";
import {
  Box,
  Input,
  Button,
  Select,
  InputGroup,
  InputRightElement,
  IconButton,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { CitationFormProps, CitationData } from "../../types/citation";
import { CopyIcon } from "@chakra-ui/icons";

const CitationForm: React.FC<CitationFormProps> = ({ onGenerateCitation }) => {
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [publisher, setPublisher] = useState("");
  const [city, setCity] = useState("");
  const [citationType, setCitationType] = useState("APA");
  const [generatedCitation, setGeneratedCitation] = useState("");
  const toast = useToast();

  const handleSubmit = () => {
    const citationData: CitationData = {
      author,
      title,
      year,
      publisher,
      city,
      citationType,
    };
    const newCitation = `${citationData.author} (${citationData.year}). ${citationData.title}. ${citationData.city}: ${citationData.publisher}.`;
    setGeneratedCitation(newCitation);
    onGenerateCitation(citationData);
  };

  const handleClearForm = () => {
    setAuthor("");
    setTitle("");
    setYear("");
    setPublisher("");
    setCity("");
    setCitationType("APA");
    setGeneratedCitation("");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedCitation);
    toast({
      title: "Cita copiada",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "bottom",
    });
  };

  return (
    <Box mb={4}>
      <Select
        placeholder="Selecciona el tipo de cita"
        value={citationType}
        onChange={(e) => setCitationType(e.target.value)}
        mb={4}
      >
        <option value="APA">APA</option>
        <option value="MLA">MLA</option>
        <option value="Chicago">Chicago</option>
        <option value="Harvard">Harvard</option>
      </Select>
      <Input
        placeholder="Autor"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        mb={2}
      />
      <Input
        placeholder="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        mb={2}
      />
      <Input
        placeholder="Año"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        mb={2}
      />
      <Input
        placeholder="Ciudad"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        mb={2}
      />
      <Input
        placeholder="Editorial"
        value={publisher}
        onChange={(e) => setPublisher(e.target.value)}
        mb={4}
      />
      <Flex justify="space-between" mb={4}>
        <Button onClick={handleSubmit} colorScheme="teal">
          Generar Cita
        </Button>
        <Button onClick={handleClearForm} colorScheme="gray">
          Limpiar
        </Button>
      </Flex>
      {generatedCitation && (
        <InputGroup>
          <Input
            value={generatedCitation}
            isReadOnly
            placeholder="Cita generada aparecerá aquí"
          />
          <InputRightElement>
            <IconButton
              icon={<CopyIcon boxSize={4} />}
              aria-label="Copiar cita"
              onClick={handleCopy}
              variant="ghost"
              size="sm"
            />
          </InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};

export default CitationForm;
