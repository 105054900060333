// src/components/auth/PrivateRoute.tsx
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { checkUserVerification } from "../../firebase/authOperations";
import { useAuth } from "../../hooks/useAuth";
import { Spinner, Flex } from "@chakra-ui/react";

const PrivateRoute: React.FC = () => {
  const { currentUser, loading } = useAuth();
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    const verifyUser = async () => {
      if (currentUser) {
        const verified = await checkUserVerification();
        setIsVerified(verified);
      } else {
        setIsVerified(false); // No autenticado, no puede estar verificado
      }
    };

    if (!loading) {
      verifyUser();
    }
  }, [currentUser, loading]);

  if (loading || isVerified === null) {
    // Mientras se está verificando el estado del usuario, muestra un loader en una tarjeta
    return (
      <Flex height="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  } else if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (!isVerified) {
    return <Navigate to="/verify-email" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoute;
