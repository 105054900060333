// src/context/AuthContext.tsx
import React, { createContext, useEffect, useState, ReactNode } from "react";
import { User, UserCredential, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import {
  signup as signupFn,
  login as loginFn,
  logout as logoutFn,
} from "../firebase/authOperations";

interface UserInfo {
  name: string;
  lastName: string;
  isVerified: boolean;
}

interface AuthContextType {
  currentUser: User | null;
  userInfo: UserInfo | null;
  loading: boolean;
  signup: (
    email: string,
    password: string,
    name: string,
    lastName: string
  ) => Promise<UserCredential>;
  login: (email: string, password: string, rememberEmail: boolean) => Promise<UserCredential>;
  logout: () => Promise<void>;
  rememberedEmail: string | null;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [rememberedEmail, setRememberedEmail] = useState<string | null>(null);

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    if (storedEmail) {
      setRememberedEmail(storedEmail);
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data() as UserInfo;
            setUserInfo(data);
          } else {
            setUserInfo(null);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
          setUserInfo(null);
        }
      } else {
        setUserInfo(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const result = await loginFn(email, password);
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
        console.log('Email stored:', localStorage.getItem('rememberedEmail')); // Debug log
      } else {
        localStorage.removeItem('rememberedEmail');
      }
      setRememberedEmail(rememberMe ? email : null);
      return result;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const value: AuthContextType = {
    currentUser,
    userInfo,
    loading,
    signup: signupFn,
    login: loginFn,
    logout: logoutFn,
    rememberedEmail,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
