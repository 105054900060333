// src/firebase/calendarOperations.ts

import { db } from "./firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { Event } from "../types/cal"; // Asegúrate de que la ruta sea correcta

// Crear un nuevo evento
export const createEvent = async (
  userId: string,
  event: Event
): Promise<string> => {
  try {
    const eventRef = await addDoc(collection(db, "users", userId, "events"), {
      ...event,
      date: Timestamp.fromDate(event.date),
    });
    console.log("Evento creado con éxito:", event);
    return eventRef.id;
  } catch (error) {
    console.error("Error creando evento:", error);
    throw error;
  }
};

// Editar un evento existente
export const editEvent = async (
  userId: string,
  eventId: string,
  updatedEvent: Partial<Event>
): Promise<void> => {
  try {
    const eventRef = doc(db, "users", userId, "events", eventId);
    await updateDoc(eventRef, {
      ...updatedEvent,
      date: updatedEvent.date
        ? Timestamp.fromDate(updatedEvent.date)
        : undefined,
    });
    console.log("Evento editado con éxito:", updatedEvent);
  } catch (error) {
    console.error("Error editando evento:", error);
    throw error;
  }
};

// Borrar un evento existente
export const deleteEvent = async (
  userId: string,
  eventId: string
): Promise<void> => {
  try {
    const eventRef = doc(db, "users", userId, "events", eventId);
    await deleteDoc(eventRef);
    console.log("Evento borrado con éxito:", eventId);
  } catch (error) {
    console.error("Error borrando evento:", error);
    throw error;
  }
};

// Obtener todos los eventos de un usuario
export const getEvents = async (userId: string): Promise<Event[]> => {
  try {
    const eventsQuery = query(collection(db, "users", userId, "events"));
    const querySnapshot = await getDocs(eventsQuery);
    const events: Event[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.title && data.date) {
        events.push({
          id: doc.id,
          date: data.date.toDate(),
          title: data.title,
        });
      } else {
        console.warn(`Event with id ${doc.id} is missing required fields`);
      }
    });
    return events;
  } catch (error) {
    console.error("Error obteniendo eventos:", error);
    throw error;
  }
};
