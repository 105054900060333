// src/components/modules/ModuleModal.tsx

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Module } from "../../types/module"; // Adjust import path as needed

interface AddEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  entity: "Módulo" | "Bloque" | "Tema";
  name: string;
  setName: (name: string) => void;
  isEditing: boolean;
  handleAddOrUpdate: () => Promise<void>;
  modules: Module[];
  selectedModuleId: string | null;
  setSelectedModuleId: React.Dispatch<React.SetStateAction<string | null>>;
}

const AddEditModal: React.FC<AddEditModalProps> = ({
  isOpen,
  onClose,
  entity,
  name,
  setName,
  isEditing,
  handleAddOrUpdate,
  modules,
  selectedModuleId,
  setSelectedModuleId,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEditing ? `Editar ${entity}` : `Agregar ${entity}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nombre</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          {entity === "Bloque" && !isEditing && (
            <FormControl mt={4}>
              <FormLabel>Módulo</FormLabel>
              <Select
                value={selectedModuleId || ""}
                onChange={(e) => setSelectedModuleId(e.target.value)}
              >
                <option value="">Seleccionar Módulo</option>
                {modules.map((module) => (
                  <option key={module.id} value={module.id}>
                    {module.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Button colorScheme="teal" mr={3} onClick={handleAddOrUpdate}>
            {isEditing ? "Actualizar" : "Agregar"}
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditModal;
