// src/main.tsx

// Aquí se inicializa la aplicación, envolviéndola en ChakraProvider, Router y AuthProvider.
// AuthProvider se coloca en el nivel superior para proporcionar el contexto de autenticación a toda la aplicación.

import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <ChakraProvider>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </ChakraProvider>
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}
