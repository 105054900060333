import { db } from "./firebaseConfig";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { Link } from "../types/link";

// Función para agregar o editar un enlace
const saveLink = async (userId: string, link: Link): Promise<void> => {
  try {
    const linkRef = doc(collection(db, "users", userId, "links"), link.id);
    const linkData = Object.fromEntries(
      Object.entries(link).filter(([key]) => key !== "id")
    );
    await setDoc(linkRef, linkData);
    console.log("Link saved successfully");
  } catch (error) {
    console.error("Error saving link:", error);
    throw error;
  }
};

// Función para obtener todos los enlaces de un usuario
const getLinks = async (userId: string): Promise<Link[]> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, "users", userId, "links")
    );
    const links: Link[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      links.push({ id: doc.id, ...data } as Link); // Mapear los datos correctamente
    });
    return links;
  } catch (error) {
    console.error("Error getting links:", error);
    throw error;
  }
};

// Función para eliminar un enlace
const deleteLink = async (userId: string, linkId: string): Promise<void> => {
  try {
    const linkRef = doc(db, "users", userId, "links", linkId);
    await deleteDoc(linkRef);
    console.log("Link deleted successfully");
  } catch (error) {
    console.error("Error deleting link:", error);
    throw error;
  }
};

// Función para actualizar un enlace
const updateLink = async (userId: string, link: Link): Promise<void> => {
  try {
    const linkRef = doc(db, "users", userId, "links", link.id);
    const linkData = Object.fromEntries(
      Object.entries(link).filter(([key]) => key !== "id")
    );
    await updateDoc(linkRef, linkData);
    console.log("Link updated successfully");
  } catch (error) {
    console.error("Error updating link:", error);
    throw error;
  }
};

export { saveLink, getLinks, deleteLink, updateLink };
