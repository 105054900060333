// src/App.tsx

import React, { useEffect } from "react";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Header from "./components/global/Header";
import Footer from "./components/global/Footer";
import Navbar from "./components/global/Navbar";
import AppRouter from "./router";
import { useAuth } from "./hooks/useAuth";
import { ProgressProvider } from "./context/ProgressContext";
import { logout } from "./firebase/authOperations";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./styles/theme";

const AppContent: React.FC = () => {
  const { isOpen, onOpen } = useDisclosure();
  const { currentUser } = useAuth();
  const location = useLocation();
  const INACTIVITY_TIME_LIMIT = 30 * 60 * 1000; // 30 minutes in milliseconds

  // Inactivity timer
  useEffect(() => {
    let timer: NodeJS.Timeout;

    const resetTimer = () => {
      clearTimeout(timer);
      if (currentUser) {
        timer = setTimeout(async () => {
          await logout();
          window.location.reload();
        }, INACTIVITY_TIME_LIMIT);
      }
    };

    const handleActivity = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [currentUser]);

  const isLandingPage = location.pathname === "/";

  return (
    <Box className="app" height="100vh">
      <Flex direction="column" height="100%">
        {!isLandingPage && <Header onOpen={onOpen} isOpen={isOpen} />}
        <Flex flex="1" direction="row" overflow="hidden">
          {!isLandingPage && (
            <Box
              display={{ base: "block", md: "block" }}
              bg={{ base: "teal", md: "white" }}
              position={{ base: "absolute", md: "relative" }}
              zIndex={{ base: "overlay", md: "auto" }}
            >
              <Navbar />
            </Box>
          )}
          <Box flex="1" overflow="auto">
            <AppRouter />
          </Box>
        </Flex>
        <Footer />
      </Flex>
    </Box>
  );
};

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <ProgressProvider>
        <AppContent />
      </ProgressProvider>
    </ChakraProvider>
  );
};

export default App;
