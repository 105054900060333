import { db } from "./firebaseConfig";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  writeBatch,
  getDoc,
  serverTimestamp,
  query,
  orderBy,
  where,
  deleteDoc,
  FieldValue,
} from "firebase/firestore";
import { Test, Question, SharedTest } from "../types/test";
import { Timestamp } from "firebase/firestore";
import { auth } from "./firebaseConfig";
import { v4 as uuidv4 } from 'uuid';

// Función para guardar un nuevo test con preguntas
const saveTestWithQuestions = async (
  userId: string,
  test: Test
): Promise<Test> => {
  try {
    const testsCollectionRef = collection(db, "users", userId, "tests");
    
    // Get the current user's display name from Firestore
    let authorName = 'Anonymous';
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      authorName = userDoc.data().name || 'Anonymous';
    }

    const testData = {
      id: test.id || uuidv4(),
      title: test.title,
      scenario: test.scenario,
      description: test.description,
      allowSkipQuestions: test.allowSkipQuestions,
      repeatIncorrectQuestions: test.repeatIncorrectQuestions,
      enableTimer: test.enableTimer ?? false,
      stats: test.stats || { attempts: 0, averageScore: 0, bestScore: 0 },
      isShared: test.isShared ?? false,
      authorName: authorName,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    const testDocRef = doc(testsCollectionRef, testData.id);
    await setDoc(testDocRef, testData);

    const questionsRef = collection(testDocRef, "questions");
    const batch = writeBatch(db);
    test.questions.forEach((question) => {
      const questionRef = doc(questionsRef, question.id);
      batch.set(questionRef, question);
    });
    await batch.commit();

    console.log("Test and questions saved successfully");

    // Return the complete Test object
    return {
      ...testData,
      questions: test.questions,
      questionCount: test.questions.length,
    };
  } catch (error) {
    console.error("Error saving test:", error);
    throw error;
  }
};

// Función para actualizar un test con preguntas
const updateTestWithQuestions = async (
  userId: string,
  testId: string,
  updatedTest: Test
): Promise<void> => {
  try {
    console.log(`Attempting to update test. UserId: ${userId}, TestId: ${testId}`);
    const testRef = doc(db, "users", userId, "tests", testId);
    
    // Check if the document exists before updating
    const testDoc = await getDoc(testRef);
    if (!testDoc.exists()) {
      console.error(`Test document not found. UserId: ${userId}, TestId: ${testId}`);
      throw new Error("Test not found");
    }

    console.log(`Test document found. Proceeding with update.`);

    await updateDoc(testRef, {
      title: updatedTest.title,
      description: updatedTest.description,
      scenario: updatedTest.scenario,
      allowSkipQuestions: updatedTest.allowSkipQuestions,
      repeatIncorrectQuestions: updatedTest.repeatIncorrectQuestions,
      enableTimer: updatedTest.enableTimer ?? false,
      stats: updatedTest.stats || {
        attempts: 0,
        averageScore: 0,
        bestScore: 0,
      },
      isShared: updatedTest.isShared ?? false,
    });

    const questionsRef = collection(testRef, "questions");
    const batch = writeBatch(db);

    updatedTest.questions.forEach((question) => {
      const questionRef = doc(questionsRef, question.id);
      batch.set(questionRef, question);
    });

    await batch.commit();
    console.log("Test and questions updated successfully");
  } catch (error) {
    console.error("Error updating test:", error);
    throw error;
  }
};

// Función para eliminar un test
const deleteTest = async (userId: string, testId: string): Promise<void> => {
  try {
    const batch = writeBatch(db);
    const testRef = doc(db, "users", userId, "tests", testId);
    
    // Get the test document to check if it's shared
    const testDoc = await getDoc(testRef);
    const testData = testDoc.data();

    // Delete questions
    const questionsRef = collection(testRef, "questions");
    const questionsSnapshot = await getDocs(questionsRef);
    questionsSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Delete the test
    batch.delete(testRef);

    // If the test was shared, delete it from sharedTests collection
    if (testData && testData.isShared && testData.sharedId) {
      const sharedTestRef = doc(db, "sharedTests", testData.sharedId);
      batch.delete(sharedTestRef);
    }

    await batch.commit();
    console.log("Test, associated questions, and shared test (if any) deleted successfully");
  } catch (error) {
    console.error("Error deleting test:", error);
    throw error;
  }
};

// Función para obtener todos los tests de un usuario
const getTests = async (userId: string): Promise<Test[]> => {
  const testsRef = collection(db, 'users', userId, 'tests');
  const testsSnapshot = await getDocs(testsRef);
  const tests: Test[] = [];

  for (const doc of testsSnapshot.docs) {
    const testData = doc.data() as Test;
    const questionsSnapshot = await getDocs(collection(doc.ref, 'questions'));
    testData.questions = questionsSnapshot.docs.map(qDoc => qDoc.data() as Question);
    tests.push({ ...testData, id: doc.id });
  }

  return tests;
};

// Función para obtener las preguntas de un test
const getTestQuestions = async (userId: string, testId: string): Promise<Question[]> => {
  try {
    const questionsRef = collection(db, "users", userId, "tests", testId, "questions");
    const questionsSnapshot = await getDocs(questionsRef);
    const questions: Question[] = [];

    questionsSnapshot.forEach((qDoc) => {
      questions.push({ id: qDoc.id, ...qDoc.data() } as Question);
    });

    return questions;
  } catch (error) {
    console.error("Error getting test questions:", error);
    throw error;
  }
};

// Función para obtener un test por ID
const getTestById = async (userId: string, testId: string): Promise<Test | null> => {
  try {
    const testRef = doc(db, "users", userId, "tests", testId);
    const testDoc = await getDoc(testRef);

    if (!testDoc.exists()) {
      return null;
    }

    const data = testDoc.data();
    const questions = await getTestQuestions(userId, testId);

    return {
      id: testDoc.id,
      title: data.title,
      scenario: data.scenario,
      description: data.description,
      allowSkipQuestions: data.allowSkipQuestions,
      repeatIncorrectQuestions: data.repeatIncorrectQuestions,
      enableTimer: data.enableTimer ?? false,
      stats: data.stats || { attempts: 0, averageScore: 0, bestScore: 0 },
      questions,
      isShared: data.isShared ?? false,
      authorName: data.authorName ?? '',
      questionCount: questions.length, // Add this line
    };
  } catch (error) {
    console.error("Error getting test by ID:", error);
    throw error;
  }
};

// Función para actualizar las estadísticas de un test
const updateTestStats = async (
  userId: string,
  testId: string,
  score: number
): Promise<void> => {
  try {
    const testRef = doc(db, "users", userId, "tests", testId);

    const testDoc = await getDoc(testRef);
    const testData = testDoc.data();

    if (testData) {
      const currentAttempts = testData.stats?.attempts || 0;
      const currentAverageScore = testData.stats?.averageScore || 0;
      const currentBestScore = testData.stats?.bestScore || 0;

      const newAttempts = currentAttempts + 1;
      const newAverageScore =
        (currentAverageScore * currentAttempts + score) / newAttempts;
      const newBestScore = Math.max(currentBestScore, score);

      await updateDoc(testRef, {
        stats: {
          attempts: newAttempts,
          averageScore: newAverageScore,
          bestScore: newBestScore,
        },
      });

      console.log("Test stats updated successfully");
    } else {
      console.error("Test data not found");
    }
  } catch (error) {
    console.error("Error updating test stats:", error);
    throw error;
  }
};

const shareTest = async (userId: string, testId: string, authorName: string): Promise<void> => {
  try {
    const testRef = doc(db, "users", userId, "tests", testId);
    const testDoc = await getDoc(testRef);

    if (!testDoc.exists()) {
      throw new Error("Test not found");
    }

    const testData = testDoc.data() as Test;

    // Get the current user's display name from Firestore
    let authorName = 'Anonymous';
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      authorName = userDoc.data().name || 'Anonymous';
    }

    // Share the test
    const sharedTestRef = doc(collection(db, "sharedTests"));
    const sharedTestData: SharedTest = {
      ...testData,
      ownerId: userId,
      originalTestId: testId,
      authorName: authorName,
      sharedTestId: sharedTestRef.id,
    };
    await setDoc(sharedTestRef, sharedTestData);
    await updateDoc(testRef, { 
      isShared: true, 
      sharedId: sharedTestRef.id, 
      sharedAt: serverTimestamp(), 
      authorName: authorName,
    });

    console.log("Test shared successfully");
  } catch (error) {
    console.error("Error sharing test:", error);
    throw error;
  }
};

const unshareTest = async (userId: string, testId: string): Promise<void> => {
  try {
    const testRef = doc(db, "users", userId, "tests", testId);
    const testDoc = await getDoc(testRef);

    if (!testDoc.exists()) {
      throw new Error("Test not found");
    }

    const testData = testDoc.data() as Test;

    if (testData.sharedId) {
      await deleteDoc(doc(db, "sharedTests", testData.sharedId));
    }
    await updateDoc(testRef, { isShared: false, sharedId: null, sharedAt: null, authorName: null });

    console.log("Test unshared successfully");
  } catch (error) {
    console.error("Error unsharing test:", error);
    throw error;
  }
};

const getSharedTests = async (): Promise<Test[]> => {
  try {
    console.log('Fetching shared tests...');
    const sharedTestsRef = collection(db, "sharedTests");
    const sharedTestsSnapshot = await getDocs(sharedTestsRef);
    const tests: Test[] = [];

    for (const doc of sharedTestsSnapshot.docs) {
      const testData = doc.data() as SharedTest;
      const questionsSnapshot = await getDocs(collection(db, "users", testData.ownerId, "tests", testData.originalTestId, "questions"));
      const questionCount = questionsSnapshot.size;

      tests.push({
        ...testData,
        id: doc.id,
        questionCount: questionCount,
      });
    }

    console.log('Fetched shared tests:', tests.length);
    return tests;
  } catch (error) {
    console.error("Error fetching shared tests:", error);
    throw error;
  }
};

const getSharedTestQuestions = async (ownerId: string, testId: string): Promise<Question[]> => {
  try {
    const questionsRef = collection(db, "users", ownerId, "tests", testId, "questions");
    const questionsSnapshot = await getDocs(questionsRef);
    return questionsSnapshot.docs.map(qDoc => ({
      id: qDoc.id,
      ...qDoc.data()
    } as Question));
  } catch (error) {
    console.error("Error fetching shared test questions:", error);
    throw error;
  }
};

const importSharedTest = async (sharedTestId: string, userId: string): Promise<void> => {
  try {
    console.log(`Importing shared test: sharedTestId=${sharedTestId}, userId=${userId}`);
    
    // Get the shared test document
    const sharedTestRef = doc(db, "sharedTests", sharedTestId);
    const sharedTestDoc = await getDoc(sharedTestRef);

    if (!sharedTestDoc.exists()) {
      throw new Error("Shared test not found");
    }

    const sharedTestData = sharedTestDoc.data() as SharedTest;
    console.log("Shared test data retrieved:", sharedTestData);

    // Create a new test document in the user's tests collection
    const newTestRef = doc(collection(db, "users", userId, "tests"));
    const { ownerId, originalTestId, ...newTestData } = sharedTestData;
    const testToSave: Test = {
      ...newTestData,
      id: newTestRef.id,
      isShared: false,
    };

    console.log("Creating new test:", testToSave);
    await setDoc(newTestRef, testToSave);

    // Copy questions to the new test
    const batch = writeBatch(db);
    const newQuestionsRef = collection(newTestRef, "questions");
    sharedTestData.questions.forEach((question) => {
      const newQuestionRef = doc(newQuestionsRef);
      batch.set(newQuestionRef, { ...question, id: newQuestionRef.id });
    });

    console.log("Committing question batch...");
    await batch.commit();

    console.log("Shared test imported successfully");
  } catch (error) {
    console.error("Error importing shared test:", error);
    throw error;
  }
};

export {
  saveTestWithQuestions,
  updateTestWithQuestions,
  deleteTest,
  getTests,
  getTestQuestions,
  getTestById,
  updateTestStats,
  shareTest,
  unshareTest,
  getSharedTests,
  getSharedTestQuestions,
  importSharedTest,
};
