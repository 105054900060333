import React from "react";
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../styles/customCalendar.css"; // Importar estilos personalizados
import { CustomCalendarProps, Event } from "../../types/cal";
import { createEvent, editEvent } from "../../firebase/calendarOperations";
import { useAuth } from "../../hooks/useAuth";

const CustomCalendar: React.FC<CustomCalendarProps> = ({
  events,
  onEventsChange,
  selectedDate,
  setSelectedDate,
  title,
  setTitle,
  isEditing,
  setIsEditing,
  eventToEdit,
  setEventToEdit,
  onOpen,
  onClose,
  isOpen,
}) => {
  const toast = useToast();
  const { currentUser } = useAuth();

  const handleDayClick = (date: Date) => {
    setSelectedDate(date);
    setIsEditing(false);
    onOpen();
  };

  const handleCloseModal = () => {
    onClose();
    setSelectedDate(null); // Reset the selected date when closing the modal
    setTitle(""); // Clear the title input
    setIsEditing(false); // Reset editing state
    setEventToEdit(null); // Clear any event being edited
  };

  const handleSaveEvent = async () => {
    if (selectedDate && title && currentUser) {
      const newEvent: Event = { date: selectedDate, title };
      let updatedEvents;
      try {
        if (isEditing && eventToEdit && eventToEdit.id) {
          await editEvent(currentUser.uid, eventToEdit.id, newEvent);
          updatedEvents = events.map((event) =>
            event.id === eventToEdit.id ? { ...newEvent, id: event.id } : event
          );
          setEventToEdit(null);
        } else {
          const eventId = await createEvent(currentUser.uid, newEvent);
          updatedEvents = [...events, { ...newEvent, id: eventId }];
        }
        onEventsChange(updatedEvents);
        toast({
          title: isEditing ? "Evento editado" : "Evento creado",
          description: `El evento ha sido ${
            isEditing ? "editado" : "creado"
          } con éxito.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTitle("");
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description: "Hubo un problema al guardar el evento.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Error",
        description: "Por favor, completa todos los campos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (
      view === "month" &&
      events.some(
        (event) => new Date(event.date).toDateString() === date.toDateString()
      )
    ) {
      return <div className="event-indicator"></div>;
    }
    return null;
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      align="flex-start"
      justify="flex-start"
      height="80%"
      p={0}
    >
      <Box
        border="0px"
        borderColor="gray.200"
        borderRadius="md"
        p={0}
        maxW="800px"
        w="full"
        h="auto"
        mr={{ md: 4 }}
      >
        <Calendar
          locale="es"
          onClickDay={handleDayClick}
          tileContent={tileContent}
          value={selectedDate} // Add this line to control the selected date
        />
      </Box>

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditing ? "Editar Evento" : "Crear Evento"}
          </ModalHeader>
          <ModalCloseButton onClick={handleCloseModal} />
          <ModalBody>
            <Input
              placeholder="Título del evento"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              mb={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleSaveEvent}>
              {isEditing ? "Guardar cambios" : "Guardar"}
            </Button>
            <Button variant="ghost" ml={3} onClick={handleCloseModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CustomCalendar;
