// src/components/Navbar.tsx
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/useAuth";
import { FaCog } from "react-icons/fa";

const Navbar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error: unknown) {
      console.error("Failed to sign out", error);
    } finally {
      onClose();
    }
  };

  const isSignupPage = location.pathname === "/signup";

  // Check if the user is authenticated and verified
  const isUserVerified = currentUser?.emailVerified;

  return (
    <Box>
      {/* Botón de menú visible en todas las pantallas */}
      <Box display="block" position="fixed" top="0" left="0" zIndex="2">
        <IconButton
          ref={btnRef}
          colorScheme="teal"
          onClick={onOpen}
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          m={{ base: "2", md: "4" }}
        />
      </Box>

      {/* Drawer para todas las pantallas */}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Misopos</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="start">
              {currentUser ? (
                isUserVerified ? (
                  <>
                    <Button as={Link} to="/home" width="100%" onClick={onClose}>
                      Inicio
                    </Button>

                    <Button
                      as={Link}
                      to="/modules"
                      width="100%"
                      onClick={onClose}
                    >
                      Módulos
                    </Button>
                    <Button
                      as={Link}
                      to="/tests"
                      width="100%"
                      onClick={onClose}
                    >
                      Tests
                    </Button>
                    <Button
                      as={Link}
                      to="/community"
                      width="100%"
                      onClick={onClose}
                    >
                      Comunidad
                    </Button>
                    <Button
                      as={Link}
                      to="/links"
                      width="100%"
                      onClick={onClose}
                    >
                      Enlaces
                    </Button>
                    <Button
                      as={Link}
                      to="/citation"
                      width="100%"
                      onClick={onClose}
                    >
                      Generador de Citas
                    </Button>
                  </>
                ) : (
                  <Button as={Link} to="/login" width="100%" onClick={onClose}>
                    Iniciar sesión
                  </Button>
                )
              ) : isSignupPage ? (
                <Button as={Link} to="/login" width="100%" onClick={onClose}>
                  Iniciar sesión
                </Button>
              ) : (
                <Button as={Link} to="/signup" width="100%" onClick={onClose}>
                  Crear cuenta
                </Button>
              )}
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            {currentUser && isUserVerified && (
              <>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    handleSignOut().catch((error: unknown) => {
                      console.error("Failed to sign out", error);
                    });
                  }}
                >
                  Cerrar sesión
                </Button>
                <IconButton
                  as={Link}
                  to="/settings"
                  aria-label="Settings"
                  icon={<FaCog />}
                  bg="transparent"
                  color="black"
                  size="lg"
                  position="absolute"
                  bottom="10px"
                  left="10px"
                  onClick={onClose}
                />
              </>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
