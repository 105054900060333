export const pdfParsingModels = {
    ModelA: {
      name: "GACE",
      regex: /(\d+\.\s.*?)(?:\n(a\).+?))(?:\n(b\).+?))(?:\n(c\).+?))(?:\n(d\).+?))(?=\n\d+\.|\Z)/gs,
    },
    ModelB: {
      name: "Model B",
      regex: /(\d+\.\s.*?)(?:\n(1\).+?))(?:\n(2\).+?))(?:\n(3\).+?))(?:\n(4\).+?))(?=\n\d+\.|\Z)/gs,
    },
    // Add more models as needed
  };
  
  export type ParsingModelKey = keyof typeof pdfParsingModels;