import React from 'react';
import { HStack, Input, Select } from '@chakra-ui/react';

interface FilterBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  sortBy: string;
  setSortBy: (sort: string) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({ searchTerm, setSearchTerm, sortBy, setSortBy }) => {
  return (
    <HStack spacing={4}>
      <Input
        placeholder="Search tests..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
        <option value="recent">Most Recent</option>
        <option value="popular">Most Popular</option>
      </Select>
    </HStack>
  );
};

export default FilterBar;