// src/pages/VerifyEmail.tsx

// Este archivo define un componente React para la página de verificación de correo electrónico.
// La página informa al usuario que se ha enviado un correo electrónico de verificación y
// proporciona un enlace para volver a la página principal después de verificar su correo.
// Utiliza componentes de Chakra UI para el diseño y el estilo, y React Router para la navegación.

import React from "react";
import {
  Box,
  Text,
  VStack,
  Card,
  CardBody,
  CardHeader,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const VerifyEmail: React.FC = () => {
  return (
    <Box p={4} textAlign="center" maxW="md" mx="auto" mt={8}>
      <VStack spacing={4} align="center">
        <Card>
          <CardHeader>
            <Text fontSize="lg" fontWeight="bold" mb={-5}>
              Email Verification
            </Text>
          </CardHeader>
          <CardBody>
            <Text>
              A verification email has been sent. Please verify your account,
              refresh this page and{" "}
              <Link as={RouterLink} to="/" color="teal.500">
                go to Home
              </Link>
              .
            </Text>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default VerifyEmail;
