import React from "react";
import {
  Box,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import { SettingsIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { HeaderProps } from "../../types/header";
import logo from "../../assets/logo.png"; // Asegúrate de ajustar la ruta al archivo del logo

const Header: React.FC<HeaderProps> = () => {
  const { currentUser } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      bg="teal"
      h={{ base: "60px", md: "70px" }}
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between" // Cambiado a "space-between" para centrar el logo
      px={4}
    >
      <Box flex="1" display="flex" justifyContent="flex-start">
        {/** Aquí puedes agregar cualquier cosa a la izquierda si es necesario */}
      </Box>

      <Box flex="1" display="flex" justifyContent="center">
        <Image src={logo} alt="Logo" h={{ base: "25px", md: "35px" }} />
      </Box>

      <Box flex="1" display="flex" justifyContent="flex-end">
        {currentUser && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <Avatar
                  size="sm"
                  name={currentUser.displayName || ""}
                  src={currentUser.photoURL || ""}
                />
              }
              variant="fill"
            />
            <MenuList>
              <MenuItem
                onClick={toggleColorMode}
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                color="black"
              >
                {colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
              </MenuItem>
              <MenuItem
                as={Link}
                to="/settings"
                icon={<SettingsIcon />}
                color="black"
              >
                Settings
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Box>
    </Box>
  );
};

export default Header;
