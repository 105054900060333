import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  useToast,
  Flex,
  Tooltip,
  Text,
  Spinner,
  useDisclosure,
  Fade,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import { useAuth } from "../hooks/useAuth";
import NewsFeed from "../components/home/NewsFeedCard";
import ProgressBar from "../components/home/ProgressBar";
import CustomCalendar from "../components/home/CustomCalendar";
import { Event } from "../types/cal";
import CalendarEventsCard from "../components/home/CalendarEventsCard";
import { getBoeSummaries } from "../services/newsBoe";
import { NewsItem } from "../types/boe";
import { deleteEvent, getEvents } from "../firebase/calendarOperations";
import { useProgress } from "../hooks/useProgress";
import { getModules } from "../firebase/moduleOperations";
import tooltips from "../content/tooltips";
import { compareAsc } from "date-fns";

const Home: React.FC = () => {
  const { userInfo, currentUser } = useAuth();
  const { progress, setModules, loadingModules } = useProgress();
  const [news, setNews] = useState<NewsItem[]>([]);
  const [loadingNews, setLoadingNews] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const toast = useToast();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [title, setTitle] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [eventToEdit, setEventToEdit] = useState<Event | null>(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  useEffect(() => {
    const fetchSummaries = async () => {
      setLoadingNews(true);
      const result = await getBoeSummaries();
      if (result) {
        const formattedNews: NewsItem[] = result.map((summary) => ({
          title: `Sumario del BOE - ${summary.date}`,
          description: "Haz clic para ver los detalles del sumario.",
          date: summary.date,
          data: summary.data,
        }));
        setNews(formattedNews);
      } else {
        toast({
          title: "Error al obtener los sumarios",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
      setLoadingNews(false);
    };

    fetchSummaries();
  }, [toast]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (currentUser) {
        setLoadingEvents(true);
        const userEvents = await getEvents(currentUser.uid);
        setEvents(userEvents);
        setLoadingEvents(false);
      }
    };

    const fetchModules = async () => {
      if (currentUser) {
        const userModules = await getModules(currentUser.uid);
        setModules(userModules);
      }
    };

    fetchEvents();
    fetchModules();
  }, [currentUser, setModules]);

  const handleEditEvent = (event: Event) => {
    setEventToEdit(event);
    setTitle(event.title);
    setSelectedDate(new Date(event.date));
    setIsEditing(true);
    onOpen();
  };

  const handleDeleteEvent = async (eventToDelete: Event) => {
    if (currentUser && eventToDelete.id) {
      try {
        await deleteEvent(currentUser.uid, eventToDelete.id);
        const updatedEvents = events.filter(
          (event) => event.id !== eventToDelete.id
        );
        setEvents(updatedEvents);
        toast({
          title: "Evento eliminado",
          description: "El evento ha sido eliminado con éxito.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Hubo un problema al eliminar el evento.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleEventsChange = (updatedEvents: Event[]) => {
    setEvents(updatedEvents);
  };

  return (
    <Box p={8} mt={-2}>
      <Flex justify="left" mb={10} mt={4}>
        <Heading as="h1" size="lg">
          ¡Hola, {userInfo?.name ?? "User"}!
        </Heading>
      </Flex>

      {loadingModules ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={-3}>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <Flex alignItems="center" mb={4}>
              <Heading as="h2" size="md" mr={2}>
                Progreso
              </Heading>
              <Tooltip
                label={tooltips.progress}
                aria-label="A tooltip"
                placement="top-end"
                hasArrow
              >
                <QuestionIcon cursor="pointer" color="teal.500" mb={1} />
              </Tooltip>
            </Flex>
            <ProgressBar progress={progress} />
          </GridItem>

          <GridItem colSpan={{ base: 2, md: 1 }} minHeight="300px">
            <Flex alignItems="center" mb={4}>
              <Heading as="h2" size="md" mr={2}>
                Últimos sumarios
              </Heading>
              <Tooltip
                label={tooltips.latestSummaries}
                aria-label="A tooltip"
                placement="top-end"
                hasArrow
              >
                <QuestionIcon cursor="pointer" color="teal.500" mb={1} />
              </Tooltip>
            </Flex>
            <Box position="relative" minHeight="250px">
              {loadingNews && (
                <Flex
                  justify="center"
                  align="center"
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                >
                  <Spinner />
                </Flex>
              )}
              <Fade in={!loadingNews} transition={{ enter: { duration: 0.5 } }}>
                {!loadingNews && <NewsFeed newsItems={news} />}
              </Fade>
            </Box>
          </GridItem>

          <GridItem colSpan={{ base: 2, md: 1 }}>
            <Flex alignItems="center" mb={4}>
              <Heading as="h2" size="md" mr={2}>
                Calendario
              </Heading>
              <Tooltip
                label={tooltips.calendar}
                aria-label="A tooltip"
                placement="top-end"
                hasArrow
              >
                <QuestionIcon cursor="pointer" color="teal.500" mb={1} />
              </Tooltip>
            </Flex>
            <CustomCalendar
              events={events}
              onEventsChange={handleEventsChange}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title={title}
              setTitle={setTitle}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              eventToEdit={eventToEdit}
              setEventToEdit={setEventToEdit}
              onOpen={onOpen}
              onClose={onClose}
              isOpen={isOpen}
            />
          </GridItem>

          <GridItem colSpan={{ base: 2, md: 1 }} minHeight="300px">
            <Flex alignItems="center" mb={4}>
              <Heading as="h2" size="md" mr={2}>
                Próximos eventos
              </Heading>
              <Tooltip
                label={tooltips.upcomingEvents}
                aria-label="A tooltip"
                placement="top-end"
                hasArrow
              >
                <QuestionIcon cursor="pointer" color="teal.500" mb={1} />
              </Tooltip>
            </Flex>
            <Box position="relative" minHeight="250px">
              {loadingEvents ? (
                <Flex
                  justify="center"
                  align="center"
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                >
                  <Spinner />
                </Flex>
              ) : (
                <Fade in={!loadingEvents} transition={{ enter: { duration: 0.5 } }}>
                  <CalendarEventsCard
                    events={events
                      .slice()
                      .sort((a, b) =>
                        compareAsc(new Date(a.date), new Date(b.date))
                      )}
                    onEdit={handleEditEvent}
                    onDelete={handleDeleteEvent}
                  />
                </Fade>
              )}
            </Box>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default Home;
