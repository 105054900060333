interface Plan {
  name: string;
  description: string;
  price: string;
  features: string[];
}

const plans: Plan[] = [
  {
    name: "Gratuito",
    description: "Acceso básico a todas las funcionalidades con limitaciones.",
    price: "Gratis",
    features: [
      "Gestión de materias y temas",
      "Progreso de estudio",
      "Calendario de eventos",
    ],
  },
  {
    name: "Básico",
    description: "Funciones adicionales para un estudio más efectivo.",
    price: "9,99€/mes",
    features: [
      "Todo en el plan gratuito",
      "Subida de documentos",
      "Soporte prioritario",
    ],
  },
  {
    name: "Premium",
    description: "Acceso completo a todas las funcionalidades.",
    price: "19,99€/mes",
    features: [
      "Todo en el plan básico",
      "Noticias y actualizaciones",
      "Acceso a materiales exclusivos",
    ],
  },
];

export default plans;
