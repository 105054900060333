import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Box,
  Text,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { FiUpload, FiFile } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import { Test } from "../../types/test";
import { TestImporterModalProps } from "../../types/test";

const TestImporterModal: React.FC<TestImporterModalProps> = ({
  isOpen,
  onClose,
  onImport,
}) => {
  const toast = useToast();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0] || null;
    setFile(uploadedFile);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleImport = () => {
    if (!file) {
      console.log("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (!data) {
        console.log("No data in file");
        return;
      }
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: string[][] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });

      console.log("Excel data:", jsonData);

      const importedTests: Test[] = processExcelData(jsonData);
      console.log("Imported tests:", importedTests);

      onImport(importedTests);

      toast({
        title: "Tests importados correctamente",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    };
    reader.readAsBinaryString(file);
  };
  const processExcelData = (data: string[][]): Test[] => {
    const tests: { [key: string]: Test } = {};
    let currentTestName: string | null = null;

    data.forEach((row, index) => {
      if (index === 0 || row.every((cell) => cell === "")) return; // Skip header row and empty rows

      const [
        testName,
        question,
        optionA,
        optionACorrect,
        optionB,
        optionBCorrect,
        optionC,
        optionCCorrect,
        optionD,
        optionDCorrect,
      ] = row;

      if (testName) {
        currentTestName = testName;
      }

      if (!currentTestName || !question) {
        console.warn(
          `Skipping row ${index + 1}: testName or question is missing`
        );
        return;
      }

      if (!tests[currentTestName]) {
        tests[currentTestName] = {
          id: uuidv4(),
          title: currentTestName,
          scenario: false,
          description: "",
          allowSkipQuestions: false,
          repeatIncorrectQuestions: false,
          enableTimer: false,
          questions: [],
          stats: {
            attempts: 0,
            averageScore: 0,
            bestScore: 0,
            totalQuestions: 0,
          },
          isShared: false,
          authorName: "",
          questionCount: 0, // Add this line
        };
      }

      const options = [
        {
          option: optionA,
          isCorrect: String(optionACorrect).toUpperCase() === "TRUE",
        },
        {
          option: optionB,
          isCorrect: String(optionBCorrect).toUpperCase() === "TRUE",
        },
        {
          option: optionC,
          isCorrect: String(optionCCorrect).toUpperCase() === "TRUE",
        },
        {
          option: optionD,
          isCorrect: String(optionDCorrect).toUpperCase() === "TRUE",
        },
      ];

      tests[currentTestName].questions.push({
        id: uuidv4(),
        question,
        options,
      });
    });

    return Object.values(tests);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar Tests</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            direction="column"
            align="center"
            justify="center"
            p={6}
            borderWidth={2}
            borderRadius="md"
            borderColor="gray.300"
            borderStyle="dashed"
            bg="gray.50"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
            cursor="pointer"
          >
            <Icon as={FiUpload} w={10} h={10} color="gray.400" mb={4} />
            <Text fontWeight="bold" mb={2}>
              {file ? file.name : "Arrastra y suelta tu archivo aquí"}
            </Text>
            <Text fontSize="sm" color="gray.500">
              o haz clic para seleccionar
            </Text>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </Flex>
          {file && (
            <Box mt={4}>
              <Flex align="center">
                <Icon as={FiFile} mr={2} />
                <Text>{file.name}</Text>
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={handleImport} disabled={!file}>
            Importar
          </Button>
          <Button variant="ghost" ml={3} onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TestImporterModal;
