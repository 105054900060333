import React from "react";
import {
  Text,
  Button,
  Flex,
  Card,
  CardHeader,
  VStack,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { IoMdPeople } from "react-icons/io";
import { TestCardProps } from "../../types/test";

const TestCard: React.FC<TestCardProps> = ({
  title,
  description,
  questionCount,
  onStart,
  onEdit,
  onDelete,
  onStats,
  onShare, // Add this prop
  isShared, // Add this prop
}) => {
  return (
    <Card
      variant="outline"
      borderRadius="md"
      overflow="hidden"
      borderWidth="1px"
      mb={2}
      boxShadow="sm"
      p={2} // Reduce el padding general de la tarjeta
      position="relative" // Necesario para posicionar el ícono en la esquina
    >
      <IconButton
        aria-label="Ver estadísticas"
        icon={<InfoIcon color="blue.500" />} // "i" azul
        size="sm"
        bg="white" // Fondo blanco
        border="0px solid" // Borde azul opcional
        borderColor="blue.500" // Color del borde azul
        position="absolute"
        top="8px"
        right="8px"
        onClick={onStats}
      />

      {isShared && (
        <IconButton
          aria-label="Compartido"
          icon={<IoMdPeople color="green.500" />}
          size="sm"
          bg="white"
          border="0px solid"
          borderColor="green.500"
          position="absolute"
          top="8px"
          right="40px"
          isDisabled={true}
        />
      )}

      <CardHeader p={2} bg="white" borderBottomWidth="0px">
        <VStack spacing={2} align="start" height="100%">
          <Text fontSize="lg" fontWeight="bold" noOfLines={1}>
            {title}
          </Text>
          <Text fontSize="xs" color="gray.600">
            Preguntas totales: {questionCount}
          </Text>
          <Box minH="60px" w="full" display="flex" alignItems="center">
            <Text fontSize="sm" color="gray.600" noOfLines={2}>
              {description}
            </Text>
          </Box>
        </VStack>
        <Flex justifyContent="center" mt="auto" pt={2}>
          <Button
            size="xs" // Tamaño del botón más pequeño para un diseño más compacto
            colorScheme="teal"
            onClick={onStart}
            mr={2}
          >
            Empezar
          </Button>
          <Button size="xs"  colorScheme="yellow" onClick={onEdit} mr={2}>
            Editar
          </Button>
          <Button size="xs" colorScheme="red" onClick={onDelete}>
            Eliminar
          </Button>
        </Flex>
      </CardHeader>
    </Card>
  );
};

export default TestCard;
