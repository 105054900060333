import React, { useState } from 'react';
import { VStack, Text, Button } from '@chakra-ui/react';
import { Test } from '../../types/test';
import SharedTestCard from './SharedTestCard';

interface SharedTestListProps {
  tests: Test[];
}

const SharedTestList: React.FC<SharedTestListProps> = ({ tests }) => {
  const [displayCount, setDisplayCount] = useState(3);

  const displayedTests = tests.slice(0, displayCount);

  return (
    <VStack spacing={2} align="stretch">
      {displayedTests.length > 0 ? (
        <>
          {displayedTests.map(test => (
            <SharedTestCard 
              key={test.id} 
              test={test} 
              questionCount={test.questionCount || 0}
            />
          ))}
          {tests.length > displayCount && (
            <Button size="sm" onClick={() => setDisplayCount(prev => prev + 3)}>
              Show More
            </Button>
          )}
        </>
      ) : (
        <Text>No shared tests available.</Text>
      )}
    </VStack>
  );
};

export default SharedTestList;