// src/components/SignupForm.tsx
import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SignupForm: React.FC = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { signup } = useAuth();
  const navigate = useNavigate();
  const toast = useToast(); // Hook para usar toasts

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    try {
      await signup(email, password, name, lastName);
      // Mostrar notificación de éxito
      toast({
        title: "Account created.",
        description:
          "You have successfully created your account. Please verify your email.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      navigate("/");
    } catch (error: unknown) {
      console.error("Failed to sign up", error);

      // Extraer mensaje de error
      const errorMessage =
        error instanceof Error ? error.message : "Failed to create an account.";

      // Mostrar notificación de error
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <form
        onSubmit={(event) => {
          handleSubmit(event).catch((error: unknown) => {
            console.error("Failed to sign up", error);
          });
        }}
      >
        <FormControl id="name" mb={2}>
          <FormLabel>Nombre</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required
          />
        </FormControl>
        <FormControl id="last-name" mb={2} mt={3}>
          <FormLabel>Apellidos</FormLabel>
          <Input
            type="text"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            required
          />
        </FormControl>
        <FormControl id="email" mb={2} mt={3}>
          <FormLabel>Correo electrónico</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </FormControl>
        <FormControl id="password" mb={2} mt={3}>
          <FormLabel>Contraseña</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </FormControl>
        <FormControl id="confirm-password" mb={8} mt={3}>
          <FormLabel>Confirmar contraseña</FormLabel>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            required
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">
          Crear cuenta
        </Button>
      </form>
    </Box>
  );
};

export default SignupForm;
