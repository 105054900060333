import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const LoginForm: React.FC = () => {
  const { login, rememberedEmail } = useContext(AuthContext) || {};
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, [rememberedEmail]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await login?.(email, password, rememberMe);
      navigate("/home"); // No es necesario esperar a que la navegación se complete
    } catch (error: unknown) {
      // Usar unknown para el tipo del parámetro catch
      console.error("Failed to log in", error);
      toast({
        title: "Login failed.",
        description:
          "The account does not exist or the credentials are incorrect.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      // Resetear el formulario
      setEmail("");
      setPassword("");
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" mb={4}>
          <FormLabel>Correo electrónico</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </FormControl>
        <FormControl id="password" mb={4}>
          <FormLabel>Contraseña</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </FormControl>
        <FormControl id="remember-me" mb={4}>
          <Checkbox
            isChecked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          >
            Recordar correo
          </Checkbox>
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">
          Iniciar sesión
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;
