// src/services/newsBoe.ts

// Este archivo contiene funciones para obtener los sumarios del BOE (Boletín Oficial del Estado)
// a través de solicitudes a la API oficial. Se generan fechas basadas en el día actual, excluyendo
// los domingos, y se realizan solicitudes HTTP para obtener los datos correspondientes a estas fechas.
// Las funciones principales en este archivo son:
// 1. `getBoeSummaries`: Obtiene los sumarios del BOE para las últimas cuatro fechas excluyendo domingos.
// 2. `formatDate`: Formatea un objeto Date a una cadena de texto en formato YYYYMMDD.
// 3. `subDays`: Resta un número específico de días a un objeto Date.

import axios, { AxiosResponse } from "axios";
import { SumarioResponse } from "../types/boe";

const API_BASE_URL = "/api"; // Usa el prefijo del proxy configurado en Vite

const getBoeSummaries = async (): Promise<
  { date: string; data: SumarioResponse }[] | null
> => {
  try {
    const today = new Date();

    // Genera fechas basadas en el día actual, excluyendo domingos
    const dates = [];
    for (let i = 0; dates.length < 4; i++) {
      const date = subDays(today, i);
      if (date.getDay() !== 0) {
        // Ignorar domingos
        dates.push(formatDate(date));
      }
    }

    // Crear las solicitudes de API
    const requests = dates.map((date) =>
      axios.get<SumarioResponse>(`${API_BASE_URL}/${date}`, {
        headers: { Accept: "application/json" },
      })
    );

    // Ejecutar todas las solicitudes y manejar los resultados
    const responses = await Promise.allSettled(requests);

    // Filtrar respuestas exitosas y mapear los datos
    const successfulResponses = responses
      .filter(
        (
          result
        ): result is PromiseFulfilledResult<AxiosResponse<SumarioResponse>> =>
          result.status === "fulfilled"
      )
      .map((result) => result.value)
      .map((response) => ({
        date: response.data.data.sumario.metadatos.fecha_publicacion,
        data: response.data,
      }));

    return successfulResponses.length === dates.length
      ? successfulResponses
      : null;
  } catch (error) {
    console.error("Error fetching BOE summaries", error);
    return null;
  }
};

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

const subDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export { getBoeSummaries };
